import { Link, useParams } from 'react-router-dom';

import { Avatar, Divider, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import AppImage from '@/components/common/AppImage';
import { useSearchResult } from '@/features/search/api/useSearchResult';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { useArtistDetail } from '../api/useArtistDetail';

import { FC } from 'react';
import 'swiper/css';
import 'swiper/css/effect-fade';

const ArtistDetail: FC = () => {
  const params = useParams();

  const queryParams = useQueryParams();
  const name = queryParams.get('name');

  const { data: artist } = useSearchResult({
    types: ['songs', 'albums', 'artists'],
    term: name ?? '',
  });

  const id = artist?.results?.artists?.data?.[0]?.id ?? params.id ?? '';

  const { data: artistDetail } = useArtistDetail(id, [
    'appears-on-albums',
    'compilation-albums',
    'featured-albums',
    'featured-music-videos',
    'featured-playlists',
    'full-albums',
    'latest-release',
    'live-albums',
    'similar-artists',
    'singles',
    'top-music-videos',
    'top-songs',
  ]);

  return (
    <Stack>
      {artistDetail?.data?.map((x) => {
        const artist = x.attributes;

        // const albums = x.relationships.albums
        const appearsOnAlbums = x?.views?.['appears-on-albums'];
        const compilationAlbums = x?.views?.['compilation-albums'];
        // const featuredAlbums = x?.views?.['featured-albums']
        const featuredMusicVideos = x?.views?.['featured-music-videos'];
        const featuredPlaylists = x?.views?.['featured-playlists'];
        const fullAlbums = x?.views?.['full-albums'];
        const latestRelease = x?.views?.['latest-release'];
        // const liveAlbums = x?.views?.['live-albums']
        const similarArtists = x?.views?.['similar-artists'];
        const singles = x?.views?.singles;
        const topSongs = x?.views?.['top-songs'];

        return (
          <Flex key={x.id} flexDir="column">
            <Flex>
              <AppImage
                artwork={artist.artwork}
                alt={artist.name ?? 'Artist Artwork'}
                width={200}
                height={200}
              />
              <Heading>{artist.name}</Heading>
            </Flex>

            {/* 最新リリース */}
            {latestRelease?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{latestRelease.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {latestRelease.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={44}
                        height={44}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* トップソング */}
            {topSongs?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{topSongs.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  <Swiper>
                    {topSongs.data?.map((y) => (
                      <SwiperSlide key={y.id}>
                        <AppImage
                          artwork={y.attributes.artwork}
                          alt={y.attributes.name}
                          width={44}
                          height={44}
                        />
                        <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Flex>
              </Flex>
            )}

            {/* アルバム */}
            {fullAlbums?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{fullAlbums.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {fullAlbums.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={44}
                        height={44}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* ミュージックビデオ */}
            {featuredMusicVideos?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{featuredMusicVideos.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {featuredMusicVideos.data?.map((y) => (
                    <Flex
                      key={y.id}
                      // TODO: Video見れるように
                      onClick={() => {
                        // play(y.attributes.url, y.type);
                      }}
                      cursor="pointer"
                    >
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* シングル */}
            {singles?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{singles.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {singles.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* コンピレーション */}
            {compilationAlbums?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{compilationAlbums.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {compilationAlbums.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* アーティストプレイリスト */}
            {featuredPlaylists?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{featuredPlaylists.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {featuredPlaylists.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            {/* アーティストプレイリスト */}
            {appearsOnAlbums?.data?.length > 0 && (
              <Flex flexDir="column">
                <Text>{appearsOnAlbums.attributes?.title}</Text>
                <Flex overflowX="scroll">
                  {appearsOnAlbums.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}

            <Divider />

            {/* 似たアーティスト */}
            {similarArtists && (
              <Flex flexDir="column">
                <Flex>
                  <Text>{similarArtists.attributes?.title}</Text>
                </Flex>
                <Flex overflowX="scroll">
                  {similarArtists.data?.map((y) => (
                    <Link key={y.id} to={getLink(y.id, y.type)}>
                      <Flex flexDir="column">
                        <Avatar
                          src={
                            y?.attributes?.artwork
                              ? MusicKit.formatArtworkURL(y.attributes.artwork, 44, 44)
                              : ''
                          }
                        />
                        <Text whiteSpace="nowrap">{y.attributes.name}</Text>
                      </Flex>
                    </Link>
                  ))}
                </Flex>
              </Flex>
            )}
            <Divider />
          </Flex>
        );
      })}
    </Stack>
  );
};

export default ArtistDetail;
