import AppImage from '@/components/common/AppImage';
import ErrorAlert from '@/components/ErrorAlert';
import { getLink } from '@/utils/getLink';
import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRecentTracks } from '../api/useRecentTracks';

const RecentPlayedList: FC = () => {
  const { data, error } = useRecentTracks();

  const tracks = data?.data ?? [];

  if (error) return <ErrorAlert />;

  return (
    <Stack>
      {tracks.map(({ id, attributes, type }) => (
        <Link key={id} to={getLink(id, type)}>
          {JSON.stringify(attributes.playParams)}
          {type}
          <AppImage artwork={attributes.artwork} alt={attributes.name} width={200} height={200} />
          <p>{id}</p>
          <a href={attributes.url}>{attributes.name}</a>
        </Link>
      ))}
    </Stack>
  );
};

export default RecentPlayedList;
