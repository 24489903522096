import { DependencyList, useEffect } from 'react';

export const usePlayerEventCallback = <T extends keyof MusicKit.Events>(
  name: T,
  cb: (event: MusicKit.Events[T]) => unknown,
  deps?: DependencyList
): void => {
  const music = MusicKit.getInstance();
  useEffect(() => {
    music.addEventListener(name, cb);
    return () => {
      music.removeEventListener(name, cb as () => unknown);
    };
  }, [cb, music, name]);
};
