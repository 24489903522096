import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import { playTrack } from '@/utils/play';
import { getYear, msToMinAndSec } from '@/utils/time';
import { Td, Text, Tr } from '@chakra-ui/react';
import { FC } from 'react';

export interface Attributes {
  discNumber: number;
  albumName: string;
  genreNames: string[];
  hasLyrics: boolean;
  trackNumber: number;
  releaseDate: string;
  durationInMillis: number;
  name: string;
  artistName: string;
  artwork: Artwork;
  playParams: PlayParams;
}

export interface AlbumItem {
  id: string;
  type: string;
  attributes: Attributes;
}
interface Props {
  albumItem: AlbumItem;
  isFocused?: boolean;
}

const AlbumListTile: FC<Props> = ({ albumItem, isFocused = false }) => {
  return (
    <Tr
      onDoubleClick={() => playTrack(albumItem.id)}
      cursor="pointer"
      w="full"
      whiteSpace="nowrap"
      borderRadius="lg"
      textOverflow="ellipsis"
      fontSize={['x-small', 'sm']}
      bgColor={isFocused ? 'app.200' : undefined}
    >
      <Td>
        <Text>{albumItem.attributes.trackNumber}</Text>
      </Td>
      <Td>
        <Text>{albumItem.attributes.name}</Text>
      </Td>
      <Td maxW="350px" whiteSpace="nowrap" overflow="hidden" textOverflow="...">
        <Text>{albumItem.attributes.artistName}</Text>
      </Td>

      <Td isNumeric>
        <Text>{getYear(albumItem.attributes.releaseDate)}</Text>
      </Td>
      <Td isNumeric>
        <time>{msToMinAndSec(albumItem.attributes.durationInMillis)}</time>
      </Td>
    </Tr>
  );
};

export default AlbumListTile;
