import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Albums from './detail/Albums';
import Songs from './detail/Songs';

type ViewType = Record<string, JSX.Element>;
const viewType: ViewType = {
  songs: <Songs />,
  artists: <div>dd</div>,
  albums: <Albums />,
};

const SearchResultDetail: FC = () => {
  const params = useParams();
  const p = params['*'];

  const Page = p ? viewType[p] : <div>SearchResultDetail</div>;

  return Page;
};

export default SearchResultDetail;
