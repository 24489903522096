import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { Description } from '@/types/description.type';
import { EditorialNotes } from '@/types/editorial-notes.type';
import { PlayParams } from '@/types/play-params.type';
import useSWR, { SWRResponse } from 'swr';

export interface Title {
  stringForDisplay: string;
  contentIds: string[];
}

export interface Attributes {
  isGroupRecommendation: boolean;
  nextUpdateDate: Date;
  resourceTypes: string[];
  title: Title;
  kind: string;
}

export interface Attributes2 {
  curatorName: string;
  lastModifiedDate: Date;
  isChart: boolean;
  name: string;
  playlistType: string;
  description: Description;
  artwork: Artwork;
  url: string;
  playParams: PlayParams;
  copyright: string;
  genreNames: string[];
  releaseDate: string;
  isMasteredForItunes?: boolean;
  upc: string;
  recordLabel: string;
  trackCount?: number;
  isCompilation?: boolean;
  isSingle?: boolean;
  artistName: string;
  isComplete?: boolean;
  editorialNotes: EditorialNotes;
  isLive?: boolean;
  mediaKind: string;
  contentRating: string;
}

export interface Datum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
}

export interface Contents {
  href: string;
  data: Datum2[];
}

export interface Relationships {
  contents: Contents;
}

export interface Data {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
  relationships: Relationships;
}

export interface Recommendations {
  data: Data[];
}

const get = (): Promise<Recommendations> =>
  api.get('v1/me/recommendations').json<Recommendations>();

export const useRecommendations = (): SWRResponse<Recommendations, any> => {
  return useSWR('my-recommendations', get);
};
