import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import StationDetail from '../components/StationDetail';
import StationList from '../components/StationList';

const StationRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<StationList />} />
      <Route path="/:id" element={<StationDetail />} />
    </Routes>
  );
};

export default StationRoute;

export const stationRoutes: RouteObject[] = [
  { index: true, element: <StationList /> },
  { path: ':id', element: <StationDetail /> },
];
