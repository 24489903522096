import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import Charts from '../components/Charts';

const ChartRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Charts />} />
    </Routes>
  );
};

export default ChartRoute;

export const chartRoutes: RouteObject[] = [{ index: true, element: <Charts /> }];
