import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import SearchResult from '../components/SearchResult';
import SearchResultDetail from '../components/SearchResultDetail';

const SearchRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchResult />} />
      <Route path="/*" element={<SearchResultDetail />} />
    </Routes>
  );
};

export default SearchRoute;

export const searchRoutes: RouteObject[] = [
  { index: true, element: <SearchResult /> },
  { path: '*', element: <SearchResultDetail /> },
];
