import useSWR, { SWRResponse } from 'swr';

import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { Description } from '@/types/description.type';
import { EditorialNotes } from '@/types/editorial-notes.type';
import { PlayParams } from '@/types/play-params.type';
import { Preview } from '@/types/preview.type';
import { getStorefront } from '@/utils/storefront';

interface Attributes {
  copyright: string;
  genreNames: string[];
  releaseDate: string;
  upc: string;
  isMasteredForItunes: boolean;
  artwork: Artwork;
  playParams: PlayParams;
  url: string;
  recordLabel: string;
  trackCount: number;
  isCompilation: boolean;
  isSingle: boolean;
  name: string;
  artistName: string;
  isComplete: boolean;
  editorialNotes?: EditorialNotes;
}

interface Attributes2 {
  albumName: string;
  genreNames: string[];
  trackNumber: number;
  releaseDate: string;
  durationInMillis: number;
  isrc: string;
  artwork: Artwork;
  playParams: PlayParams;
  url: string;
  discNumber: number;
  isAppleDigitalMaster: boolean;
  hasLyrics: boolean;
  name: string;
  previews: Preview[];
  artistName: string;
}

interface Datum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
}

interface Tracks {
  href: string;
  data: Datum2[];
}

interface Attributes3 {
  trackCount: number;
  genreNames: string[];
  releaseDate: string;
  name: string;
  artistName: string;
  artwork: Artwork;
  dateAdded: Date;
  playParams: PlayParams;
}

interface Datum3 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes3;
}

interface Library {
  href: string;
  data: Datum3[];
}

interface Datum4 {
  id: string;
  type: string;
  href: string;
}

interface Artists {
  href: string;
  data: Datum4[];
}

interface Relationships {
  tracks: Tracks;
  library: Library;
  artists: Artists;
}

interface Attributes4 {
  title: string;
}

interface Attributes5 {
  curatorName: string;
  lastModifiedDate: Date;
  isChart: boolean;
  name: string;
  description: Description;
  playlistType: string;
  artwork: Artwork;
  url: string;
  playParams: PlayParams;
}

interface Datum5 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes5;
}

interface AppearsOn {
  href: string;
  next: string;
  attributes: Attributes4;
  data: Datum5[];
}

interface Attributes6 {
  title: string;
}

interface Metrics {
  reco_displayType: string;
  reco_eligible: string;
  reco_algoId: string;
}

interface Meta {
  metrics: Metrics;
  count: number;
  types: string[];
}

interface Attributes7 {
  copyright: string;
  genreNames: string[];
  releaseDate: string;
  upc: string;
  isMasteredForItunes: boolean;
  artwork: Artwork;
  playParams: PlayParams;
  url: string;
  recordLabel: string;
  trackCount: number;
  isCompilation: boolean;
  isSingle: boolean;
  name: string;
  artistName: string;
  isComplete: boolean;
}

interface Datum6 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes7;
}

interface RelatedAlbums {
  href: string;
  attributes: Attributes6;
  data: Datum6[];
  meta: Meta;
}

interface Views {
  'appears-on': AppearsOn;
  'related-albums': RelatedAlbums;
}

interface Views2 {
  order: string[];
}

interface Meta2 {
  views: Views2;
}

interface Datum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
  relationships: Relationships;
  views: Views;
  meta: Meta2;
}

interface AlbumDetailMeta {
  data: Datum[];
}

export const get = (id: string): Promise<AlbumDetailMeta> => {
  return api
    .get(`v1/catalog/${getStorefront()}/albums/${id}`, {
      searchParams: {
        views: 'related-albums,appears-on',
        include: 'library',
      },
    })
    .json<AlbumDetailMeta>();
};

export const useAlbumDetailMeta = (id: string): SWRResponse<AlbumDetailMeta, any> => {
  return useSWR(`album-detail-${id}-meta`, () => get(id));
};
