import AppImage from '@/components/common/AppImage';
import Cover from '@/components/common/Cover';
import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSearchResultDetail } from '../../api/useSearchResultDetail';

const Albums: FC = () => {
  const queryParams = useQueryParams();
  const term = queryParams.get('term') ?? '';

  const {
    data: searchResult,
    isValidating,
    size,
    setSize,
  } = useSearchResultDetail({
    types: ['albums'],
    term,
  });

  const albums = searchResult?.flatMap?.((x) => x?.results?.albums?.data) ?? [];
  const next = searchResult?.flatMap?.((x) => x)?.at(-1)?.results?.albums?.next;

  const hasMore = next && !isValidating;
  // const last = !next && !isValidating

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <>
      <Flex flexDir="column" justify="center" align="center">
        <Box w="90%" my="4">
          <Heading>{term}</Heading>
        </Box>
        <Divider my="2" />
      </Flex>
      <Flex flexDir="column">
        <Cover my="10">
          <Flex justify="space-evenly" wrap="wrap" py="4">
            {albums?.map((x) => (
              <Link key={x.id} to={getLink(x?.id, x.type)}>
                <Box p="4">
                  <AppImage
                    artwork={x.attributes.artwork}
                    alt={x.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Box w={200} fontSize="12px" mt="2" mb="4">
                    <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {x.attributes.name}
                    </Text>
                    <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {x.attributes.artistName}
                    </Text>
                  </Box>
                </Box>
              </Link>
            ))}
          </Flex>
        </Cover>
      </Flex>

      <Box ref={ref} pb="4">
        {isValidating && (
          <Box my="4">
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Albums;
