import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import useSWR, { SWRResponse } from 'swr';

export interface Attributes {
  copyright: string;
  genreNames: string[];
  releaseDate: string;
  isMasteredForItunes: boolean;
  upc: string;
  artwork: Artwork;
  playParams: PlayParams;
  url: string;
  recordLabel: string;
  trackCount: number;
  isCompilation: boolean;
  isSingle: boolean;
  name: string;
  contentRating: string;
  artistName: string;
  isComplete: boolean;
}

export interface Attributes2 {
  genreNames: string[];
  name: string;
  artwork: Artwork;
  url: string;
}

export interface Datum3 {
  id: string;
  type: string;
  href: string;
}

export interface Albums {
  href: string;
  next: string;
  data: Datum3[];
}

export interface Relationships2 {
  albums: Albums;
}

export interface Datum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
  relationships: Relationships2;
}

export interface Artists {
  href: string;
  data: Datum2[];
}

export interface Relationships {
  artists: Artists;
}

export interface Datum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
  relationships: Relationships;
}

export interface MyAlbumMeta {
  data: Datum[];
}

export const get = (id: string): Promise<MyAlbumMeta> =>
  api
    .get(`v1/me/library/albums/${id}/catalog`, {
      searchParams: {
        include: 'artists',
      },
    })
    .json<MyAlbumMeta>();

export const useMyAlbumMeta = (id: string): SWRResponse<MyAlbumMeta, any> => {
  return useSWR(`my-album-detail-${id}-meta`, () => get(id));
};
