import AppImage from '@/components/common/AppImage';
import { play } from '@/utils/play';
import { Box, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useStationDetail } from '../api/useStationDetail';

const StationDetail: FC = () => {
  const params = useParams();
  const id = params.id ?? '';

  const { data } = useStationDetail(id);

  return (
    <Stack>
      <Text>
        {JSON.stringify(data)}
        {(data?.data as any[])?.map((x) => (
          <Box key={x.id} onClick={() => play(x.attributes.url)} cursor="pointer">
            <AppImage
              artwork={x.attributes.artwork}
              alt={x.attributes.name}
              width={200}
              height={200}
            />
            <Text>{x.attributes.name}</Text>
          </Box>
        ))}
      </Text>
    </Stack>
  );
};

export default StationDetail;
