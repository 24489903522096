import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import Recommendations from '../components/Recommendations';

const RecommendationRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Recommendations />} />
    </Routes>
  );
};

export default RecommendationRoute;

export const recommendationRoutes: RouteObject[] = [
  {
    index: true,
    element: <Recommendations />,
  },
];
