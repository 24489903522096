import { volumeState } from '@/state/playback';
import { Flex, Slider, SliderFilledTrack, SliderTrack, useBoolean } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import VolumeIcon from '../icon/VolumeIcon';

const Volume: FC = () => {
  const [isHovered, setIsHovered] = useBoolean();
  const music = MusicKit.getInstance();
  const [volume, setVolume] = useRecoilState(volumeState);
  const handleSeeking = useCallback(
    (num: number) => {
      setVolume(num);
      music.volume = num;
    },
    [music, setVolume]
  );

  return (
    <Flex align="center" justify="space-between">
      <VolumeIcon volume={volume} />
      <Slider
        value={volume}
        onChange={handleSeeking}
        minW="100px"
        maxW="200px"
        min={0}
        max={1}
        step={0.0001}
        ml={2}
      >
        <SliderTrack
          onMouseEnter={setIsHovered.on}
          onMouseLeave={setIsHovered.off}
          h={isHovered ? 2 : 1}
          _active={{
            h: isHovered ? 2 : 1,
          }}
        >
          <SliderFilledTrack
            bg={isHovered ? 'whiteAlpha.900' : 'whiteAlpha.700'}
            h={isHovered ? 2 : 1}
            _active={{
              bg: isHovered ? 'whiteAlpha.900' : 'whiteAlpha.700',
              h: isHovered ? 2 : 1,
            }}
          />
        </SliderTrack>
      </Slider>
    </Flex>
  );
};

export default Volume;
