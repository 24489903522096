import { FC, useMemo } from 'react';

import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';

import AppImage from '@/components/common/AppImage';
import PlaybackControlls from '@/components/common/PlaybackControlls';
import { useNowPlayingItem } from '@/hooks/useNowPlayingItem';
import { useQueueItems } from '@/hooks/useQueueItems';

const QueueList: FC = () => {
  // const items = useRecoilValue(queueList);

  const nowPlayingItem = useNowPlayingItem(true);
  const items = useQueueItems();

  return (
    <VStack>
      <QueueItem item={nowPlayingItem} />

      <PlaybackControlls />

      <Divider py="2" />

      <Box w="full">
        {items.map((x) => (
          <QueueItem key={x.id} item={x} nowPlayingItem={nowPlayingItem} />
        ))}
      </Box>
    </VStack>
  );
};

export default QueueList;

interface Props {
  item?: MusicKit.MediaItem;
  nowPlayingItem?: MusicKit.MediaItem;
}

const QueueItem: FC<Props> = ({ item, nowPlayingItem }) => {
  const music = useMemo(() => MusicKit.getInstance(), []);

  return (
    <Flex
      onClick={() => {
        const items = music.queue.items ?? [];
        const index = items.findIndex((x) => x.id === item?.id);
        index >= 0 && music.changeToMediaAtIndex(index);
      }}
      justifyContent="space-between"
      w="full"
      cursor="pointer"
      p="2"
      borderRadius="md"
      fontSize="12px"
      bgColor={item?.id === nowPlayingItem?.id ? 'selected' : undefined}
    >
      <Flex alignItems="center">
        <AppImage artwork={item?.artwork} alt={item?.title ?? 'Song Name'} width={44} height={44} />
        <Flex flexDir="column" ml="2">
          <Text>{item?.attributes?.name}</Text>
          <Text color="gray.500">{item?.attributes?.artistName}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
