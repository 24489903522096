import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { Description } from '@/types/description.type';
import { EditorialNotes } from '@/types/editorial-notes.type';
import { PlayParams } from '@/types/play-params.type';
import { Preview } from '@/types/preview.type';
import { getStorefront } from '@/utils/storefront';
import useSWR, { SWRResponse } from 'swr';

export interface Attributes {
  albumName: string;
  genreNames: string[];
  trackNumber: number;
  releaseDate: string;
  durationInMillis: number;
  isrc: string;
  artwork: Artwork;
  composerName: string;
  url: string;
  playParams: PlayParams;
  discNumber: number;
  hasLyrics: boolean;
  isAppleDigitalMaster: boolean;
  name: string;
  previews: Preview[];
  artistName: string;
}

export interface Datum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
}

export interface Song {
  chart: string;
  name: string;
  orderId: string;
  next: string;
  data: Datum[];
  href: string;
}

export interface Attributes2 {
  curatorName: string;
  lastModifiedDate: Date;
  isChart: boolean;
  name: string;
  playlistType: string;
  description: Description;
  artwork: Artwork;
  playParams: PlayParams & {
    versionHash: string;
  };
  url: string;
}

export interface Datum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
}

export interface Playlist {
  chart: string;
  name: string;
  orderId: string;
  next: string;
  data: Datum2[];
  href: string;
}

export interface Attributes3 {
  copyright: string;
  genreNames: string[];
  releaseDate: string;
  upc: string;
  isMasteredForItunes: boolean;
  artwork: Artwork;
  url: string;
  playParams: PlayParams;
  recordLabel: string;
  isCompilation: boolean;
  trackCount: number;
  isSingle: boolean;
  name: string;
  artistName: string;
  editorialNotes: EditorialNotes;
  isComplete: boolean;
  contentRating: string;
}

export interface Datum3 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes3;
}

export interface Album {
  chart: string;
  name: string;
  orderId: string;
  next: string;
  data: Datum3[];
  href: string;
}

export interface Results {
  songs: Song[];
  playlists: Playlist[];
  albums: Album[];
}

export interface Results2 {
  order: string[];
}

export interface Meta {
  results: Results2;
}

export interface Charts {
  results: Results;
  meta: Meta;
}

const get = (): Promise<Charts> =>
  api
    .get(`v1/catalog/${getStorefront()}/charts`, {
      searchParams: {
        types: 'songs,albums,playlists',
        with: 'cityCharts,dailyGlobalTopCharts',
        chart: 'most-played',
        limit: 200,
      },
    })
    .json<Charts>();

export const useCharts = (): SWRResponse<Charts, any> => {
  return useSWR('charts', get);
};
