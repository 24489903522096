import { getAlbumId } from './stringUtil';

export const getLink = (id?: string, type?: string, url?: string): string => {
  if (!id) return '#';

  switch (type) {
    case 'playlists':
      return `/playlists/${id}`;
    case 'library-playlists':
      return `/playlists/library/${id}`;
    case 'albums':
      return `/albums/${id}`;
    case 'library-albums':
      return `/albums/library/${id}`;
    case 'artists':
      return `/artists/${id}`;
    case 'library-artists':
      return `/artists/library/${id}`;
    case 'song':
    case 'songs': {
      const albumId = getAlbumId(url) ?? '';
      return `/albums/${albumId}`;
    }
    case 'stations':
      return `/stations/${id}`;
    default:
      console.warn('unknown id or type id:', id, 'type:', type);
      return '#';
  }
};
