import { fetcher } from '@/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import { Options } from 'ky';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

export interface Attributes {
  discNumber: number;
  albumName: string;
  genreNames: string[];
  trackNumber: number;
  hasLyrics: boolean;
  releaseDate: string;
  durationInMillis: number;
  name: string;
  artistName: string;
  artwork: Artwork;
  playParams: PlayParams;
  contentRating: string;
}

export interface Datum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
}

export interface Meta {
  total: number;
}

export interface MyAlbumTracks {
  next?: string;
  data: Datum[];
  meta: Meta;
}

const defaultOptions = {
  limit: 100,
  offset: 0,
};

export const useMyAlbumTracks = (id: string): SWRInfiniteResponse<MyAlbumTracks, any> => {
  return useSWRInfinite<MyAlbumTracks>(
    (pageIndex, prev) => {
      if (prev && !prev.next) {
        return null;
      }

      const url = `v1/me/library/albums/${id}/tracks`;
      const offset = pageIndex * defaultOptions.limit;
      const opts: Options = {
        searchParams: {
          offset,
        },
      };

      return { url, opts };
    },
    ({ url, opts }) => fetcher(url, opts),
    {
      revalidateAll: true,
      revalidateOnFocus: false,
      persistSize: true,
    }
  );
};
