import { fetcher } from '@/api';
import { SearchResult } from '@/types/search-result.type';
import { getStorefront } from '@/utils/storefront';
import { Options } from 'ky';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';
import { SearchTypes, SearchWith } from '../types/search-types';

interface Params {
  types: SearchTypes[];
  term: string;
  searchWith?: SearchWith;
  limit?: number;
  offset?: number;
}

const defaultOptions = {
  limit: 25,
  offset: 0,
};

export const useSearchResultDetail = (params: Params): SWRInfiniteResponse<SearchResult, any> => {
  return useSWRInfinite(
    (pageIndex, prev) => {
      if (
        prev &&
        !prev.results?.songs?.next &&
        !prev.results?.artists?.next &&
        !prev.results?.albums?.next
      ) {
        return null;
      }

      const offset = pageIndex * defaultOptions.limit;

      const url = `v1/catalog/${getStorefront()}/search`;
      const opts: Options = {
        searchParams: {
          term: params.term,
          types: params.types.join(','),
          limit: params.limit ?? defaultOptions.limit,
          offset: offset ?? defaultOptions.offset,
        },
      };

      return { url, opts };
    },
    ({ url, opts }) => fetcher<SearchResult>(url, opts),
    {
      revalidateAll: true,
      revalidateOnFocus: false,
      persistSize: true,
    }
  );
};
