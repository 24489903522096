import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import ArtistDetail from '../components/ArtistDetail';
import MyArtistDetail from '../components/MyArtistDetail';
import MyArtistList from '../components/MyArtistList';

const ArtistRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ArtistDetail />} />
      <Route path="/:id" element={<ArtistDetail />} />
      <Route path="/library" element={<MyArtistList />} />
      <Route path="/library/:id" element={<MyArtistDetail />} />
    </Routes>
  );
};

export default ArtistRoute;

export const artistRoutes: RouteObject[] = [
  { index: true, element: <ArtistDetail /> },
  { path: ':id', element: <ArtistDetail /> },
  { path: 'library', element: <MyArtistList /> },
  { path: 'library/:id', element: <MyArtistDetail /> },
];
