import { useCallback, useState } from 'react';
import { usePlayerEventCallback } from './usePlayerEventCallback';

const getNowPlayingItem = (fallbackToQueue: boolean): MusicKit.MediaItem | undefined => {
  const music = MusicKit.getInstance();
  const mediaItem = music.nowPlayingItem;
  if (mediaItem) return mediaItem;
  if (fallbackToQueue && music.queue) {
    return music.queue.item(Math.max(0, music.queue.position));
  }
};

export const useNowPlayingItem = (fallbackToQueue = false): MusicKit.MediaItem | undefined => {
  const [value, setValue] = useState<MusicKit.MediaItem | undefined>(() =>
    getNowPlayingItem(fallbackToQueue)
  );

  const update = useCallback(() => {
    setValue(getNowPlayingItem(fallbackToQueue));
  }, [fallbackToQueue]);
  usePlayerEventCallback(MusicKit.Events.nowPlayingItemDidChange, update, []);
  usePlayerEventCallback(MusicKit.Events.queueItemsDidChange, update, []);
  return value;
};
