import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getLink } from '@/utils/getLink';
import { FormControl, Input, InputGroup, useBoolean } from '@chakra-ui/react';
import { useSearchSuggestions } from '../api/useSearchSuggestions';

interface Options {
  id?: string;
  type?: string;
  searchTerm?: string;
  url?: string;
}

const SearchBar: FC = () => {
  const navigate = useNavigate();
  const [isInputting, setIsInputting] = useBoolean();
  const [term, setTerm] = useState('');
  const ref = useRef<HTMLInputElement>(null);
  const { data } = useSearchSuggestions(term, [
    'albums',
    'artists',
    'playlists',
    'songs',
    'music-videos',
  ]);

  useEffect(() => {
    if (term || document.activeElement === ref.current) {
      setIsInputting.on();
    } else {
      setIsInputting.off();
    }
  }, [setIsInputting, term]);

  const suggestions = (data?.results?.suggestions ?? []).filter((x) => x.searchTerm !== term);

  const getTo = (kind: string, { id, type, searchTerm, url }: Options): string => {
    if (kind === 'terms') {
      return `/search?term=${searchTerm ?? ''}`;
    } else {
      return getLink(id, type, url);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        navigate(`/search?term=${term}`);
      }}
    >
      <FormControl>
        <InputGroup>
          <Input
            onChange={(e) => setTerm(e.target.value)}
            placeholder="Search"
            autoComplete="off"
          />
        </InputGroup>
      </FormControl>
    </form>
  );

  // return (
  //   <>
  //     <Popover
  //       isOpen={isInputting}
  //       onOpen={setIsInputting.on}
  //       onClose={setIsInputting.off}
  //       closeOnBlur={true}
  //       // lazyBehavior='keepMounted'
  //       defaultIsOpen
  //       autoFocus={false}
  //       returnFocusOnClose={false}
  //     >
  //       <PopoverAnchor>
  //         <form
  //           onSubmit={(e) => {
  //             e.preventDefault();
  //             navigate(`/search?term=${term}`);
  //           }}
  //         >
  //           <FormControl>
  //             <InputGroup>
  //               <Input
  //                 onChange={(e) => setTerm(e.target.value)}
  //                 placeholder='Search'
  //                 autoComplete='off'
  //               />
  //             </InputGroup>
  //           </FormControl>
  //         </form>
  //       </PopoverAnchor>

  //       {suggestions?.length > 0 && (
  //         <PopoverContent
  //           bg='rgb(24, 24, 24)'
  //           w='300px'
  //           maxH='full'
  //           overflow='scroll'
  //         >
  //           <PopoverArrow />
  //           <PopoverCloseButton />
  //           <PopoverHeader>{'   '}</PopoverHeader>
  //           <PopoverBody>
  //             <Flex flexDir='column' fontSize='xs' whiteSpace='pre-wrap'>
  //               {suggestions.map((x, key) => (
  //                 <Link
  //                   key={x.id}
  //                   to={getTo(x.kind, {
  //                     id: x?.content?.id,
  //                     type: x?.content?.type,
  //                     url: x?.content?.attributes?.url,
  //                     searchTerm: x?.searchTerm,
  //                   })}
  //                 >
  //                   <Flex
  //                     py='2'
  //                     borderRadius='4px'
  //                     _hover={{
  //                       bgColor: 'selected',
  //                     }}
  //                   >
  //                     {x?.kind === 'terms' ? (
  //                       <Text>{x.displayTerm}</Text>
  //                     ) : (
  //                       <>
  //                         {x?.content?.type !== 'artists' ? (
  //                           <AppImage
  //                             artwork={x?.content?.attributes?.artwork}
  //                             alt={x?.content?.attributes?.name ?? '画像'}
  //                             width={40}
  //                             height={40}
  //                           />
  //                         ) : (
  //                           <AppAvatar
  //                             artwork={x?.content?.attributes?.artwork}
  //                             width={40}
  //                             height={40}
  //                           />
  //                         )}
  //                         <Flex flexDir='column' mx='2' justify='center'>
  //                           <Text whiteSpace='break-spaces'>
  //                             {x?.content?.attributes.name}
  //                           </Text>
  //                           <Text color='gray.400'>{x?.content?.type}</Text>
  //                         </Flex>
  //                       </>
  //                     )}
  //                   </Flex>
  //                 </Link>
  //               ))}
  //             </Flex>
  //           </PopoverBody>
  //         </PopoverContent>
  //       )}
  //     </Popover>
  //   </>
  // );
};

export default SearchBar;
