import { useNowPlayingItem } from '@/hooks/useNowPlayingItem';
import { usePlayerRef } from '@/hooks/usePlayerRef';
import { currentTimeState, durationState, remainingTimeState } from '@/state/playback';
import { formatTime } from '@/utils/time';
import {
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppImage from '../common/AppImage';

const MediaBar: FC = () => {
  const navigate = useNavigate();
  const playerRef = usePlayerRef();

  const [isHovered, setIsHovered] = useBoolean();

  const duration = useRecoilValue(durationState);
  const [currentTime, setCurrentTime] = useRecoilState(currentTimeState);
  const remainingTime = useRecoilValue(remainingTimeState);
  const nowPlayingItem = useNowPlayingItem(true);

  const handleSeeking = useCallback(
    (n: number) => {
      if (playerRef) {
        playerRef.currentTime = n;
        setCurrentTime(n);
      }
    },
    [playerRef, setCurrentTime]
  );

  const metadata = nowPlayingItem?._assets?.[0]?.metadata;
  const albumId = metadata?.playlistId;
  const artistId = metadata?.artistId;
  const trackId = metadata?.itemId ?? '';

  return (
    <Flex data-tauri-drag-region w="500px" maxW="550px" my="2" maxH="44px" overflow="hidden">
      <Menu isLazy>
        <MenuButton disabled={!artistId && !albumId}>
          <AppImage
            artwork={nowPlayingItem?.artwork}
            alt={nowPlayingItem?.info ?? 'Artwork'}
            width={44}
            height={44}
          />
        </MenuButton>
        <MenuList>
          {artistId && (
            <MenuItem
              onClick={() =>
                navigate(`/artists/${artistId}`, {
                  preventScrollReset: true,
                })
              }
            >
              Artist
            </MenuItem>
          )}
          {albumId && (
            <MenuItem
              onClick={() =>
                navigate(`/albums/${albumId}?i=${trackId}`, {
                  preventScrollReset: true,
                })
              }
            >
              Album
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Flex
        data-tauri-drag-region
        flexDir="column"
        justifyContent="space-between"
        alignItems="center"
        mx="1"
        w="full"
      >
        {nowPlayingItem && (
          <>
            <Grid
              data-tauri-drag-region
              templateColumns={['repeat(1, 1fr)', 'repeat(8, 1fr)']}
              gap={0}
              w="full"
              h="full"
            >
              <GridItem
                colStart={0}
                colEnd={1}
                as={Flex}
                flexDir="column"
                justify="end"
                align="center"
                w="28px"
              >
                <Text fontSize="xx-small">{formatTime(currentTime)}</Text>
              </GridItem>
              <GridItem colStart={1} colEnd={9} as={Flex} justify="center" align="center">
                <Flex
                  flexDir="column"
                  fontSize="xs"
                  overflowX="hidden"
                  textOverflow="ellipsis"
                  w="full"
                  mx={1}
                >
                  <Flex justifyContent="center" wordBreak="break-all" lineHeight="none" w="full">
                    <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {nowPlayingItem?.title}
                    </Text>
                  </Flex>
                  <Flex
                    justifyContent="center"
                    whiteSpace="nowrap"
                    // animation={`${flowing} 14s linear infinite`}
                    // transform='translateX(120%)'
                  >
                    <Text>{nowPlayingItem?.artistName}</Text>
                    <Text mx={2}>-</Text>
                    <Text>{nowPlayingItem?.albumName}</Text>
                  </Flex>
                </Flex>
              </GridItem>
              <GridItem
                as={Flex}
                colStart={9}
                colEnd={10}
                flexDir="column"
                justify="end"
                align="center"
                w="28px"
              >
                <Text fontSize="xx-small">{`-${formatTime(remainingTime)}`}</Text>
              </GridItem>
            </Grid>

            <Slider
              max={duration}
              value={currentTime}
              focusThumbOnChange={false}
              onChange={handleSeeking}
              isDisabled={duration === 0}
              // mt={1}
              py={1}
              h="4px"
            >
              <SliderTrack
                onMouseEnter={setIsHovered.on}
                onMouseLeave={setIsHovered.off}
                h={isHovered ? 2 : 1}
                _active={{
                  h: isHovered ? 2 : 1,
                }}
              >
                <SliderFilledTrack
                  bg={isHovered ? 'whiteAlpha.900' : 'whiteAlpha.700'}
                  h={isHovered ? 2 : 1}
                  _active={{
                    bg: isHovered ? 'whiteAlpha.900' : 'whiteAlpha.700',
                    h: isHovered ? 2 : 1,
                  }}
                />
              </SliderTrack>
            </Slider>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default MediaBar;
