import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import Header from './header/Header';
import SideBar from './sidebar/SideBar';

interface Props {
  children?: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <>
      <Grid
        templateAreas={` 
          "header header"
          "nav main"
          "nav main"
        `}
        gridTemplateColumns="260px 1fr"
        gridTemplateRows="60px 1fr 0px"
        w="100vw"
        h="100vh"
      >
        <GridItem as="header" area="header" data-tauri-drag-region minH="60px">
          <Header />
        </GridItem>
        <GridItem as="nav" area="nav">
          <SideBar />
        </GridItem>
        <GridItem as="main" area="main" overflow="hidden">
          <Stack w="full" minW="full" h="full" overflow="scroll">
            <ScrollRestoration />
            {children}
          </Stack>
        </GridItem>
      </Grid>
    </>
  );
};

export default Layout;
