import { api } from '@/lib/api';
import { ResponsePromise } from 'ky';

interface Params {
  types: 'ids[albums]' | 'ids[songs]';
  ids: string;
}

export const addToLibrary = (params: Params): ResponsePromise =>
  api.post(`v1/me/library?${params.types}=${params.ids}`);
