import { ChakraProps, Flex } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  children?: JSX.Element;
} & ChakraProps;

const Cover: FC<Props> = ({ children, ...props }) => {
  return (
    <>
      {children && (
        <Flex justify="center" {...props}>
          <Flex justify="center" w="90%" bgColor="rgba(32, 32, 32, 0.5)" borderRadius="10px">
            {children}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default Cover;
