import { fetcher } from '@/api';
import { Artwork } from '@/types/artwork.type';
import { Options } from 'ky';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

export interface MyArtists {
  next: string;
  data: Daum[];
  meta: Meta;
}

export interface Daum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
  relationships: Relationships;
}

export interface Attributes {
  name: string;
}

export interface Relationships {
  catalog: Catalog;
}

export interface Catalog {
  href: string;
  data: Daum2[];
}

export interface Daum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
  relationships: Relationships2;
}

export interface Attributes2 {
  genreNames: string[];
  name: string;
  artwork: Artwork;
  url: string;
}

export interface Relationships2 {
  albums: Albums;
}

export interface Albums {
  href: string;
  data: Daum3[];
  next?: string;
}

export interface Daum3 {
  id: string;
  type: string;
  href: string;
}

export interface Meta {
  total: number;
}

const defaultOptions = {
  limit: 100,
};

export const useMyArtists = (): SWRInfiniteResponse<MyArtists, any> => {
  return useSWRInfinite(
    (pageIndex: number, prev: MyArtists) => {
      if (prev && !prev.next) {
        return null;
      }

      const url = 'v1/me/library/artists';
      const offset = pageIndex * defaultOptions.limit;
      const opts: Options = {
        searchParams: {
          limit: defaultOptions.limit,
          offset,
          include: 'catalog',
        },
      };

      return { url, opts };
    },

    ({ url, opts }) => fetcher<MyArtists>(url, opts),
    {
      revalidateAll: true,
      // revalidateOnMount: true,
      persistSize: true,
    }
  );
};
