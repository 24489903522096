import { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { addToLibrary } from '@/api/library';
import AppImage from '@/components/common/AppImage';
import { useQueryParams } from '@/hooks/useQueryParams';
import { play, playAlbum } from '@/utils/play';
import { MdAdd, MdCheck } from 'react-icons/md';
import { useAlbumDetailMeta } from '../api/useAlbumDetailMeta';
import { useAlbumDetailTracks } from '../api/useAlbumDetailTracks';

const AlbumDetail: FC = () => {
  const id = useParams().id ?? '';
  const trackId = useQueryParams().get('i');

  const { data: albumsMeta } = useAlbumDetailMeta(id);
  const { data: albumTracks } = useAlbumDetailTracks(id);

  const music = useMemo(() => MusicKit.getInstance(), []);

  const albumMeta = albumsMeta?.data?.[0];
  const artist = albumMeta?.relationships?.artists?.data?.[0];
  const library = albumMeta?.relationships?.library?.data?.[0];

  const relatedAlbums = albumMeta?.views?.['related-albums'];
  // const appearsOn = albumMeta?.views?.['appears-on']

  return (
    <Box>
      {!library?.id && (
        <Button onClick={() => addToLibrary({ types: 'ids[albums]', ids: albumMeta?.id ?? '' })}>
          Add
        </Button>
      )}
      <Flex>
        <AppImage
          artwork={albumMeta?.attributes?.artwork}
          alt={albumMeta?.attributes?.name ?? 'Album Name'}
          width={300}
          height={300}
        />
        <Flex flexDir="column">
          <Heading>{albumMeta?.attributes?.name}</Heading>
          <Link to={`/artists/${artist?.id ?? ''}`}>
            <Heading color="app.100">{albumMeta?.attributes?.artistName}</Heading>
          </Link>
          <Text>{albumMeta?.attributes?.genreNames?.join?.(', ')}</Text>
          <Text
            dangerouslySetInnerHTML={{
              __html:
                albumMeta?.attributes?.editorialNotes?.short ??
                albumMeta?.attributes?.editorialNotes?.standard ??
                '',
            }}
          />
          <Button onClick={() => playAlbum(albumMeta?.id)}>Play</Button>
          <Button
            onClick={() => {
              music.shuffleMode = 1;
              playAlbum(albumMeta?.id, {
                startPosition: albumMeta?.relationships?.tracks?.data?.length,
              });
            }}
          >
            Shuffle
          </Button>
        </Flex>
      </Flex>

      <Divider />
      {albumTracks?.data?.map((x, index) => {
        // const trackArtists = x?.relationships?.artists?.data
        // const trackGenres = x?.relationships?.genres?.data
        // const trackLibrary = x?.relationships?.library?.data?.[0]

        return (
          <Box key={x.id}>
            <Box>
              <Flex
                onDoubleClick={async () => {
                  music.shuffleMode = 0;
                  play(x.attributes.url, {
                    startPosition: index,
                  });
                }}
                justifyContent="space-between"
                cursor="pointer"
                my="2"
                color={trackId === x.id ? 'app.100' : undefined}
                _hover={{
                  bgColor: 'selected',
                }}
                borderRadius="md"
              >
                <Box color="app.100">
                  {library?.id ? (
                    <IconButton
                      icon={<MdCheck />}
                      aria-label="Already Added to Library"
                      variant="ghost"
                      rounded="full"
                    />
                  ) : (
                    <IconButton
                      icon={<MdAdd />}
                      onClick={() =>
                        addToLibrary({
                          types: 'ids[songs]',
                          ids: x.id,
                        })
                      }
                      aria-label="Add Muisc To Library"
                      variant="ghost"
                      rounded="full"
                    />
                  )}
                </Box>
                <Text>
                  {x.attributes.trackNumber}
                  <Box as="span" pl="4" />
                  {x.attributes.name}
                </Text>

                <Text>{MusicKit.formatMediaTime(x.attributes.durationInMillis / 1000, ':')}</Text>
                <Menu>
                  <MenuButton>h</MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        addToLibrary({
                          types: 'ids[songs]',
                          ids: x.id,
                        });
                      }}
                    >
                      Add
                    </MenuItem>
                    <MenuItem>Create a Copy</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>

              <Divider />
            </Box>
          </Box>
        );
      })}

      {relatedAlbums?.data && relatedAlbums.data.length > 0 && (
        <>
          <Text>{relatedAlbums.attributes.title}</Text>
          <Swiper slidesPerView={5} pagination={{ clickable: true }} modules={[Pagination]}>
            <Flex>
              {relatedAlbums.data.map((y) => (
                <SwiperSlide key={y.id}>
                  <Link to={`/albums/${y.id}`}>
                    <Flex flexDir="column" justify="center" align="center">
                      <AppImage
                        artwork={y.attributes.artwork}
                        alt={y.attributes.name}
                        width={100}
                        height={100}
                      />
                      <Text w={100} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {y.attributes.name}
                      </Text>
                    </Flex>
                  </Link>
                </SwiperSlide>
              ))}
            </Flex>
          </Swiper>
        </>
      )}
    </Box>
  );
};

export default AlbumDetail;
