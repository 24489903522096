import AppImage from '@/components/common/AppImage';
import Cover from '@/components/common/Cover';
import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { getLink } from '@/utils/getLink';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useMyAlbumList } from '../api/useMyAlbumList';

const MyAlbumList: FC = () => {
  const { data, isValidating, size, setSize } = useMyAlbumList();

  const albums = data?.flatMap((x) => x.data);

  const next = data?.flatMap((x) => x)?.at(-1)?.next;
  // const total = data?.flatMap((x) => x)?.at(-1)?.meta?.total

  const hasMore = next && !isValidating;
  // const last = !next && !isValidating

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <Stack>
      <Cover my="10">
        <Flex justify="space-evenly" wrap="wrap" py="4">
          {albums?.map((x) => (
            <Link key={x.id} to={getLink(x.id, x.type)}>
              <AppImage
                artwork={x.attributes.artwork}
                alt={x.attributes.name}
                width={200}
                height={200}
              />
              <Box w={200} fontSize="12px" mt="2" mb="4">
                <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  {x.attributes.name}
                </Text>
                <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  {x.attributes.artistName}
                </Text>
              </Box>
            </Link>
          ))}
        </Flex>
      </Cover>
      <div ref={ref}>
        {isValidating && (
          <Box my="4">
            <Loading />
          </Box>
        )}
      </div>
    </Stack>
  );
};

export default MyAlbumList;
