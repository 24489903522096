import AppImage from '@/components/common/AppImage';
import { getLink } from '@/utils/getLink';
import { getAlbumId } from '@/utils/stringUtil';
import { Box, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useCharts } from '../api/useCharts';

const Charts: FC = () => {
  const { data } = useCharts();

  const albumsCharts = data?.results?.albums ?? [];
  const playlistsCharts = data?.results?.playlists ?? [];
  const tracksCharts = data?.results?.songs ?? [];
  return (
    <Stack>
      <Flex flexDir="column">
        {albumsCharts.map((x) => (
          <Flex key={x.name}>
            <Text>{x.name}</Text>
            <Box>
              {x.data.map((y) => (
                <Link key={y.id} to={getLink(y.id, y.type)}>
                  <AppImage
                    artwork={y.attributes.artwork}
                    alt={y.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Text>{y.attributes.name}</Text>
                </Link>
              ))}
            </Box>
          </Flex>
        ))}
        <Divider />
        {playlistsCharts.map((x) => (
          <Flex key={x.name}>
            <Text>{x.name}</Text>
            <Box>
              {x.data.map((y) => (
                <Link key={y.id} to={getLink(y.id, y.type)}>
                  <AppImage
                    artwork={y.attributes.artwork}
                    alt={y.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Text>{y.attributes.name}</Text>
                </Link>
              ))}
            </Box>
          </Flex>
        ))}
        <Divider />

        {tracksCharts.map((x) => (
          <Flex key={x.name}>
            <Text>{x.name}</Text>
            <Box>
              {x.data.map((y) => (
                <Link key={y.id} to={`/albums/${getAlbumId(y.attributes.url) ?? ''}`}>
                  <AppImage
                    artwork={y.attributes.artwork}
                    alt={y.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Text>{y.attributes.name}</Text>
                </Link>
              ))}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Stack>
  );
};

export default Charts;
