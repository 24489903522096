import AlbumListTile from '@/components/common/AlbumListTile';
import AppImage from '@/components/common/AppImage';
import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { playAlbum } from '@/utils/play';
import { Box, Button, Flex, Heading, Table } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMyAlbumMeta } from '../api/useMyAlbumMeta';
import { useMyAlbumTracks } from '../api/useMyAlbumTracks';

// TODO: ローリングストーンズの300曲に対応する
const MyAlbumDetail: FC = () => {
  const params = useParams();
  const id = params.id ?? '';
  const queryParams = useQueryParams();
  const trackId = queryParams.get('i');
  const { data: albumMetaData } = useMyAlbumMeta(id);
  const { data: album, isValidating, setSize, size } = useMyAlbumTracks(id);

  const artist = albumMetaData?.data?.[0];
  const albumMeta = albumMetaData?.data?.[0];

  const tracks = album?.flatMap((x) => x.data);

  const next = album?.at(-1)?.next;
  const hasMore = !!next && !isValidating;

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <>
      <Flex>
        <AppImage
          artwork={albumMeta?.attributes?.artwork}
          alt={albumMeta?.attributes?.name ?? 'Album Artwork'}
          width={200}
          height={200}
        />
        <Flex flexDir="column">
          <Link to={`/artists/?name=${albumMeta?.attributes?.artistName ?? ''}`}>
            <Heading>{albumMeta?.attributes?.artistName}</Heading>
          </Link>
          <Link to={getLink(artist?.id, artist?.type)}>
            <Heading>{albumMeta?.attributes?.name}</Heading>
          </Link>
        </Flex>
        <Button
          onClick={() => {
            MusicKit.getInstance().shuffleMode = 1;
            playAlbum(albumMeta?.id);
          }}
        >
          Shuffle
        </Button>
        <Button onClick={() => playAlbum(albumMeta?.id)}>Play</Button>
      </Flex>

      {tracks?.map((x) => (
        <Box key={x.id}>
          <Table>
            <AlbumListTile albumItem={x} isFocused={trackId === x.id} />
          </Table>
        </Box>
      ))}
      <Box ref={ref} pb="4">
        {isValidating && (
          <Box my="4">
            <Loading />
          </Box>
        )}
      </Box>

      {!hasMore && <>{tracks?.length}曲</>}
    </>
  );
};

export default MyAlbumDetail;
