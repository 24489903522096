import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import HeavyRotation from '../components/HeavyRotation';
import RecentAddedItemList from '../components/RecentAddedItemList';
import RecentPlayedList from '../components/RecentPlayedList';

const HistoryRoute: FC = () => {
  return (
    <Routes>
      <Route path="/heavy-rotation" element={<HeavyRotation />} />;
      <Route path="/recent-played" element={<RecentPlayedList />} />;
      <Route path="/recently-added" element={<RecentAddedItemList />} />;
    </Routes>
  );
};

export default HistoryRoute;

export const historyRoutes: RouteObject[] = [
  { path: 'heavy-rotation', element: <HeavyRotation /> },
  { path: 'recent-played', element: <RecentPlayedList /> },
  { path: 'recently-added', element: <RecentAddedItemList /> },
];
