interface Options {
  length?: number;
  startPosition?: number;
}

export const playTrack = (trackId: string, options?: Options): void => {
  const music = MusicKit.getInstance();

  const startPosition = options?.startPosition ?? options?.length ?? 0;

  try {
    music.setQueue({
      song: trackId,
      startPosition,
      startPlaying: true,
    });
  } catch (e) {
    console.error(e);
    music.stop();
  }
};

export const playAlbum = (albumId?: string, options?: Options): void => {
  const music = MusicKit.getInstance();

  const startPosition = options?.startPosition ?? options?.length ?? 0;

  try {
    music.setQueue({
      album: albumId,
      startPosition,
      startPlaying: true,
    });
  } catch (e) {
    console.error(e);
    music.stop();
  }
};
export const playPlaylist = (playlistId?: string, options?: Options): void => {
  const music = MusicKit.getInstance();

  const startPosition = options?.startPosition ?? options?.length ?? 0;

  try {
    music.setQueue({
      playlist: playlistId,
      startPlaying: true,
      startPosition,
    });
  } catch (e) {
    console.error(e);
    music.stop();
  }
};

export const play = (url?: string, options?: Options): void => {
  const music = MusicKit.getInstance();

  const startPosition = options?.startPosition ?? options?.length ?? 0;

  try {
    music.setQueue({
      url,
      startPlaying: true,
      startPosition,
    });
  } catch (e) {
    console.error(e);
    music.stop();
  }
};

export const playOrStop = (): void => {
  const music = MusicKit.getInstance();
  switch (music.playbackState as unknown as MusicKit.PlaybackStates) {
    case MusicKit.PlaybackStates.playing:
      music.pause();
      break;
    case MusicKit.PlaybackStates.paused:
    case MusicKit.PlaybackStates.stopped:
      music.play();
      break;
  }
};
