import { MyAlbums } from '@/features/album/api/useMyAlbumList';
import { MyArtists } from '@/features/artist/api/useMyArtists';
import { PlaylistTracks } from '@/features/playlist/api/useMyPlaylistTracks';

type ItemPattern = MyAlbums | PlaylistTracks | MyArtists;

interface Args<T> {
  pageIndex: number;
  prev: T | null;
  keyId: string;
  limit: number;
}

type GetKeyRes = {
  keyId: string;
  offset: number;
} | null;

// keyIdはSWRのkeyになる
export const getKey = <T extends ItemPattern>({
  prev,
  pageIndex,
  keyId,
  limit,
}: Args<T>): GetKeyRes => {
  if (prev && !prev.next) {
    return null;
  }

  const offset = pageIndex * limit;

  return { keyId, offset };
};
