import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import { getKey } from '@/utils/getKey';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

export interface MyAlbums {
  next?: string;
  data: Daum[];
  meta: Meta;
}

export interface Daum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
}

export interface Attributes {
  trackCount: number;
  genreNames: string[];
  releaseDate?: string;
  name: string;
  artistName: string;
  artwork: Artwork;
  dateAdded: string;
  playParams: PlayParams;
}

export interface Meta {
  total: number;
}

interface Params {
  offset?: number;
}

const limit = 100;

const get = (params?: Params): Promise<MyAlbums> =>
  api
    .get('v1/me/library/albums', {
      searchParams: {
        limit,
        offset: params?.offset ?? 0,
      },
    })
    .json<MyAlbums>();

export const useMyAlbumList = (): SWRInfiniteResponse<MyAlbums, any> => {
  return useSWRInfinite(
    (pageIndex, prev) => getKey({ keyId: `${pageIndex}`, pageIndex, prev, limit }),
    ({ offset }) => get({ offset }),
    {
      revalidateAll: true,
      revalidateOnFocus: false,
      persistSize: true,
    }
  );
};
