import { Box, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Box>
      <Heading>エラーが発生しました。</Heading>
      <Text>{JSON.stringify(error.message)}</Text>
      <Text>{JSON.stringify(error.stack)}</Text>
      <Text>{JSON.stringify(error.name)}</Text>
      <button type="button" onClick={resetErrorBoundary}>
        もう一度、実行する
      </button>
    </Box>
  );
};
export default ErrorFallback;
