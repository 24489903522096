export const getYear = (date?: string): string => {
  if (!date) return '';
  return `${date[0]}${date[1]}${date[2]}${date[3]}`;
};

export const msToMinAndSec = (ms?: number): string => {
  if (!ms) return '00:00';

  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor((ms / 1000 / 60 / 60) % 24);

  let formattedTime: string[] = [];

  if (hours === 0) {
    formattedTime = [minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')];
  } else {
    formattedTime = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ];
  }

  return formattedTime.join(':');
};

export const formatTime = (secs: number | undefined): string => {
  if (secs === undefined || isNaN(secs) || secs === Infinity) {
    return '--:--';
  }
  const minutesPart = String(Math.floor(secs / 60));
  const secsPart = String(Math.floor(secs % 60)).padStart(2, '0');
  return `${minutesPart}:${secsPart}`;
};
