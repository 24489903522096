import AppImage from '@/components/common/AppImage';
import { getLink } from '@/utils/getLink';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMyArtistDetail } from '../api/useMyArtistDetail';

const MyArtistDetail: FC = () => {
  const params = useParams();
  const id = params.id ?? '';
  const { data: albums } = useMyArtistDetail(id);

  const size = albums?.data?.length;

  return (
    <Stack>
      <Flex flexDir="column">
        {albums?.data?.map((x) => (
          <Link key={x.id} to={getLink(x.id, x.type)}>
            <AppImage
              artwork={x.attributes.artwork}
              alt={x.attributes.name}
              width={200}
              height={200}
            />
            <Text>{x.attributes.name}</Text>
            <Text>{x.type}</Text>
          </Link>
        ))}
      </Flex>
      <Box>
        <Text>{size} tracks</Text>
        <Text>{albums?.meta?.total} tracks</Text>
      </Box>
    </Stack>
  );
};

export default MyArtistDetail;
