import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '@/hooks/useAuth';
import { FC } from 'react';
import Header from './header/Header';

interface Props {
  open: boolean;
}

const LoginModal: FC<Props> = ({ open }) => {
  const { login } = useAuth();

  return (
    <Stack>
      <Modal isOpen={open} onClose={() => {}} size="full" isCentered>
        <ModalOverlay />
        <ModalContent bgColor="appBg.100">
          <Header />

          <ModalBody my="10">
            <VStack h="full" spacing="4" my="4">
              <Button variant="outline" bgColor="app.200" onClick={login}>
                Login
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default LoginModal;
