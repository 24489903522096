import { Box, Flex, IconButton } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { MdFastForward, MdFastRewind } from 'react-icons/md';
import PlayStopIcon from '../icon/PlayStopIcon';
import RepeatIcon from '../icon/repeat/RepeatIcon';
import ShuffleIcon from '../icon/shuffle/ShuffleIcon';

const Playback: FC = () => {
  const music = useMemo(() => MusicKit.getInstance(), []);

  return (
    <Flex justify="center" align="center">
      <ShuffleIcon />
      <IconButton
        onClick={() => music.skipToPreviousItem()}
        icon={<MdFastRewind size="24px" />}
        aria-label="play previous item"
        variant="ghost"
        rounded="full"
      />
      <Box mx="1">
        <PlayStopIcon />
      </Box>
      <IconButton
        onClick={() => music.skipToNextItem()}
        icon={<MdFastForward size="24px" />}
        aria-label="play next item"
        variant="ghost"
        rounded="full"
      />
      <RepeatIcon />
    </Flex>
  );
};

export default Playback;
