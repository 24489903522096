import ky from 'ky';

export const api = ky.create({
  prefixUrl: 'https://api.music.apple.com',
  hooks: {
    beforeRequest: [
      async (req) => {
        if (req.url.startsWith('https://api.music.apple.com')) {
          const music = MusicKit.getInstance();

          if (music?.musicUserToken) {
            req.headers.set('Music-User-Token', music.musicUserToken);
          }
          if (music?.developerToken) {
            req.headers.set('Authorization', `Bearer ${music.developerToken}`);
          }
        }
      },
    ],
  },
});
