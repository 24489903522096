import { atom, selector } from 'recoil';

export const durationState = atom({
  key: 'duration',
  default: 0,
});
export const currentTimeState = atom({
  key: 'currentTime',
  default: 0,
});
export const remainingTimeState = atom({
  key: 'remainingTime',
  default: 0,
});

export const volumeState = atom({
  key: 'volume',
  default: 0.5,
});

export const enum PlaybackState {
  NONE = 0,
  LOADING = 1,
  PLAYING = 2,
  PAUSED = 3,
  STOPPED = 4,
  ENDED = 5,
  SEEKING = 6,
  waiting = 7,
  stalled = 8,
  completed = 9,
}
export const playbackState = atom({
  key: 'playback',
  default: PlaybackState.NONE,
});

export const enum RepeatMode {
  none = 0,
  one = 1,
  all = 2,
}
export const repeatModeState = atom({
  key: 'repeatMode',
  default: RepeatMode.none,
});

export const enum ShuffleMode {
  off = 0,
  songs = 1,
}
export const shuffleModeState = atom({
  key: 'shuffleMode',
  default: ShuffleMode.off,
});
export const shuffleModeSelector = selector({
  key: 'shuffleModeSelector',
  get: ({ get }) => get(shuffleModeState),
  set: ({ set }, newValue) => {
    const music = MusicKit.getInstance();
    if (typeof newValue === 'number' && newValue < 3) {
      music.shuffleMode = newValue.valueOf();
      set(shuffleModeState, newValue);
    }
  },
});
