import { useRecoilValue } from 'recoil';

import { playbackState, PlaybackState } from '@/state/playback';
import { Box, IconButton } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { FaPlay } from 'react-icons/fa';
import { MdPause } from 'react-icons/md';
import Loading from '../Loading';

const PlayStopIcon: FC = () => {
  const music = useMemo(() => MusicKit.getInstance(), []);
  const playback = useRecoilValue(playbackState);

  if (playback === PlaybackState.LOADING) {
    return (
      <Box m="2">
        <Loading />
      </Box>
    );
  }

  if (playback === PlaybackState.PLAYING) {
    return (
      <IconButton
        onClick={() => music.pause()}
        icon={<MdPause size="24px" />}
        aria-label="pause item"
        variant="ghost"
        color="app.200"
        rounded="full"
      />
    );
  }

  return (
    <IconButton
      onClick={() => music.play()}
      icon={<FaPlay />}
      disabled={playback === PlaybackState.NONE}
      _disabled={{
        cursor: 'auto',
        opacity: 0.4,
      }}
      aria-label="play item"
      variant="ghost"
      rounded="full"
    />
  );
};

export default PlayStopIcon;
