import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdMinimize } from 'react-icons/md';

type Props = IconButtonProps;

const AppMinimizeIcon: FC<Props> = (props) => {
  return <IconButton icon={<MdMinimize />} variant="ghost" {...props} />;
};

export default AppMinimizeIcon;
