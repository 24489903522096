import { api } from '@/lib/api';
import { getStorefront } from '@/utils/storefront';
import useSWR, { SWRResponse } from 'swr';

const get = (): Promise<unknown> =>
  api.get(`v1/catalog/${getStorefront()}/stations?filter[identity]=personal`).json();

export const useStationList = (): SWRResponse<unknown, any> => {
  return useSWR('stations', get);
};
