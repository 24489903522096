import { api } from '@/lib/api';
import { Description } from '@/types/description.type';
import { PlayParams } from '@/types/play-params.type';
import useSWR, { SWRResponse } from 'swr';

export interface HeavyRotationTracks {
  data: Track[];
}

export interface Track {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
}

export interface Attributes {
  canEdit?: boolean;
  name: string;
  description?: Description;
  isPublic?: boolean;
  hasCatalog?: boolean;
  dateAdded: string;
  playParams: PlayParams;
  trackCount?: number;
  genreNames?: string[];
  releaseDate?: string;
  artistName?: string;
  artwork?: Artwork;
}

interface Artwork {
  width: number;
  height: number;
  url: string;
}

const get = (): Promise<HeavyRotationTracks> =>
  api.get('v1/me/history/heavy-rotation').json<HeavyRotationTracks>();

export const useHeavyRotation = (): SWRResponse<HeavyRotationTracks, any> => {
  return useSWR(['heavy-rotation'], get, {
    revalidateOnFocus: false,
  });
};
