import { useAuth } from '@/hooks/useAuth';
import { Box, Button, Flex, Stack, Switch, useColorMode } from '@chakra-ui/react';
import { FC } from 'react';

const SettingList: FC = () => {
  const { colorMode, setColorMode } = useColorMode();
  const { signOut } = useAuth();

  const clearCaches = (): void => {
    const isOk = confirm('Are you sure you want to clear caches?');
    if (isOk) {
      // localStorage.clear();
      // sessionStorage.clear();
      // window.location.reload();
    }
  };

  return (
    <Stack>
      <Flex justify="space-between" align="center">
        <Box>ダークモード</Box>
        <Switch
          isChecked={colorMode === 'dark'}
          onChange={() => (colorMode === 'dark' ? setColorMode('light') : setColorMode('dark'))}
        />
      </Flex>
      <Flex justify="center" align="center">
        <Button onClick={clearCaches} m="100px">
          Clear Caches
        </Button>
      </Flex>
      <Flex justify="center" align="center">
        <Button onClick={signOut} bgColor="app.100" m="100px">
          Sign Out
        </Button>
      </Flex>
    </Stack>
  );
};

export default SettingList;
