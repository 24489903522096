import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';

export interface RecentAddedItemList {
  next?: string;
  data: Data[];
}

export interface Data {
  id: string;
  type: string;
  href?: string;
  attributes: Attributes;
}

export interface Attributes {
  trackCount: number;
  genreNames: string[];
  releaseDate?: string;
  name: string;
  artistName?: string;
  artwork?: Artwork;
  playParams: PlayParams;
  dateAdded: string;
}

interface Params {
  limit?: number;
  offset?: number;
}
const defaultOptions = {
  limit: 25,
  offset: 0,
};

export const get = (params?: Params): Promise<RecentAddedItemList> =>
  api
    .get('v1/me/library/recently-added', {
      searchParams: {
        limit: params?.limit ?? defaultOptions.limit,
        offset: params?.offset ?? defaultOptions.offset,
      },
    })
    .json<RecentAddedItemList>();

export const useRecentAddedItemList = (): SWRInfiniteResponse<RecentAddedItemList, any> => {
  return useSWRInfinite(
    (pageKey, prev: RecentAddedItemList | null) => {
      if (prev && !prev.next) return null;
      const offset = pageKey * defaultOptions.limit;
      return { offset };
    },
    ({ offset }) => get({ offset }),
    {
      revalidateAll: true,
      revalidateOnMount: true,
      persistSize: true,
      revalidateOnFocus: true,
    }
  );
};
