import { Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useStationList } from '../api/useStationList';

const StationList: FC = () => {
  const { data } = useStationList();
  return (
    <Stack>
      <Text>{JSON.stringify(data)}</Text>
    </Stack>
  );
};

export default StationList;
