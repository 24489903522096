import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import ErrorFallback from '@/components/common/ErrorFallback';
import { APPLE_MUSIC_TOKEN } from '@/constants/env';
import { router } from '@/lib/router';
import AuthProvider from '@/providers/AuthProvider';
import { theme } from '@/styles/theme';
import { swrMiddleware } from './middlewares/swrMiddleware';

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

(async () => {
  const music = await MusicKit.configure({
    developerToken: APPLE_MUSIC_TOKEN,
    app: {
      name: 'My Apple Music',
      icon: '/my-apple-music.svg',
    },
  });

  app.render(
    <StrictMode>
      <ErrorBoundary fallbackRender={ErrorFallback}>
        <RecoilRoot>
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <AuthProvider music={music}>
              <SWRConfig
                value={{
                  revalidateOnFocus: false,
                  shouldRetryOnError: false,
                  revalidateOnReconnect: true,
                  errorRetryCount: 0,
                  use: [swrMiddleware],
                }}
              >
                <RouterProvider router={router} />
              </SWRConfig>
            </AuthProvider>
          </ChakraProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </StrictMode>
  );
})();
