import { RepeatMode, repeatModeState } from '@/state/playback';
import { IconButton } from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { RepeatAllIcon, RepeatNoneIcon, RepeatOneIcon } from './RepeatModeIcon';

const repeatModes = [RepeatMode.all.valueOf(), RepeatMode.none.valueOf(), RepeatMode.one.valueOf()];

const icons = {
  [RepeatMode.none]: <RepeatNoneIcon />,
  [RepeatMode.one]: <RepeatOneIcon />,
  [RepeatMode.all]: <RepeatAllIcon />,
};

const RepeatIcon: FC = () => {
  const [count, setCount] = useState(0);
  const [repeatMode, setRepeatMode] = useRecoilState(repeatModeState);

  const music = useMemo(() => MusicKit.getInstance(), []);

  const handleClick = useCallback(() => {
    setCount((prev) => (prev += 1));
    const mode = (count + 1) % repeatModes.length;
    music.repeatMode = mode;
    setRepeatMode(mode);
  }, [count, music, setRepeatMode]);

  return (
    <IconButton
      onClick={handleClick}
      icon={icons[repeatMode]}
      aria-label="set repeat mode"
      variant="ghost"
      rounded="full"
    />
  );
};

export default RepeatIcon;
