import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import AlbumDetail from '../components/AlbumDetail';
import MyAlbumDetail from '../components/MyAlbumDetail';
import MyAlbumList from '../components/MyAlbumList';

const AlbumRoute: FC = () => {
  return (
    <Routes>
      <Route path="/:id" element={<AlbumDetail />} />
      <Route path="/library" element={<MyAlbumList />} />
      <Route path="/library/:id" element={<MyAlbumDetail />} />
    </Routes>
  );
};

export default AlbumRoute;

export const albumRoutes: RouteObject[] = [
  { path: ':id', element: <AlbumDetail /> },
  { path: 'library', element: <MyAlbumList /> },
  { path: 'library/:id', element: <MyAlbumDetail /> },
];
