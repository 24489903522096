import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdArrowBackIos } from 'react-icons/md';

type Props = IconButtonProps;

const GoBackIcon: FC<Props> = (props) => {
  return <IconButton icon={<MdArrowBackIos />} variant="ghost" {...props} />;
};

export default GoBackIcon;
