import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { usePlayerEventCallback } from '@/hooks/usePlayerEventCallback';
import { currentTimeState, durationState, remainingTimeState, volumeState } from '@/state/playback';
import { getVolume } from '@/utils/volumeUtil';

export const useAppSettings = (): void => {
  const music = useMemo(() => MusicKit.getInstance(), []);
  const navigate = useNavigate();

  const setDuration = useSetRecoilState(durationState);
  const setCurrentTime = useSetRecoilState(currentTimeState);
  const setRemainingTime = useSetRecoilState(remainingTimeState);
  const setVolume = useSetRecoilState(volumeState);

  if (import.meta.env.PROD) {
    document.addEventListener('contextmenu', (e) => e.preventDefault());
  }

  const path = localStorage.getItem('app-path');
  useEffect(() => {
    path && navigate(path);
  }, [path]);

  const volume = getVolume();
  music.volume = volume;
  setVolume(volume);

  const changeTime = useCallback(() => {
    if (!Number.isNaN(music.currentPlaybackDuration)) {
      setDuration(music.currentPlaybackDuration);
    }
    if (!Number.isNaN(music.currentPlaybackTime)) {
      setCurrentTime(music.currentPlaybackTime);
    }
    if (!Number.isNaN(music.currentPlaybackTimeRemaining)) {
      setRemainingTime(music.currentPlaybackTimeRemaining);
    }
  }, [
    music.currentPlaybackDuration,
    music.currentPlaybackTime,
    music.currentPlaybackTimeRemaining,
    setCurrentTime,
    setDuration,
    setRemainingTime,
  ]);
  usePlayerEventCallback(MusicKit.Events.playbackDurationDidChange, changeTime, []);
  usePlayerEventCallback(MusicKit.Events.playbackTimeDidChange, changeTime, []);
};
