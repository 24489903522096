import { FC, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, Flex, Text } from '@chakra-ui/react';
import { MdOutlinePlaylistPlay, MdPlaylistAdd } from 'react-icons/md';

import { useMyPlaylists } from '@/features/playlist/api/useMyPlaylists';
import { getLink } from '@/utils/getLink';

const SideBarPlaylists: FC = () => {
  const location = useLocation();
  const { data } = useMyPlaylists();
  const playlists = data?.data ?? [];

  // const { cache, mutate, ...extraConfig } = useSWRConfig()

  // const navigate = useNavigate()

  const createNewPlaylist = useCallback(async () => {
    // const playlists = await createPlaylist({
    //   name: 'New Playlist',
    // });
    // const newPlaylist = playlists?.data?.find((x) => x?.id !== undefined);
    // const id = newPlaylist?.id;
    // if (id) {
    //   navigate(`/playlists/library/${id}`);
    // }
    // mutate('my-playlists');
  }, []);

  return (
    <>
      <Flex
        onClick={createNewPlaylist}
        align="center"
        px="4"
        py="4"
        my="1"
        ml="2"
        cursor="pointer"
        borderRadius="md"
        _hover={{ bgColor: 'selected' }}
      >
        <MdPlaylistAdd size="24px" />
        <Text ml="2">Create New Playlist</Text>
      </Flex>
      <Box pb="4">
        {playlists.map((x) => (
          <Link key={x.id} to={getLink(x.id, x.type)}>
            <Flex
              align="center"
              px="4"
              py="2"
              my="1"
              ml="2"
              borderRadius="md"
              color={
                `${location.pathname}${location.search}` === `/playlists/library/${x.id as string}`
                  ? 'white'
                  : undefined
              }
              bgColor={
                `${location.pathname}${location.search}` === `/playlists/library/${x.id as string}`
                  ? 'selected'
                  : undefined
              }
              _hover={{ bgColor: 'selected' }}
            >
              <MdOutlinePlaylistPlay size="24px" />
              <Text ml="2" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {x.attributes.name}
              </Text>
            </Flex>
          </Link>
        ))}
      </Box>
    </>
  );
};

export default SideBarPlaylists;
