import { Flex, Image, Skeleton } from '@chakra-ui/react';
import { CSSProperties, FC, memo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import DefaultIcon from '@/assets/default-apple-artwork.svg';

const imageFit: CSSProperties = {
  borderRadius: '4px',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: 'auto',
  width: 'auto',
  maxWidth: '100%',
  maxHeight: '100%',
  margin: 'auto',
};

interface Props {
  artwork?: MusicKit.Artwork;
  alt: string;
  width: number;
  height: number;
  style?: CSSProperties;
}

const AppImage: FC<Props> = ({ artwork, alt, width, height, style }) => {
  return (
    <Flex justify="center" align="center" pos="relative">
      {!artwork ? (
        <DefaultImage width={width} height={height} style={style} />
      ) : (
        <LazyLoadImage
          src={MusicKit.formatArtworkURL(artwork, height, width)}
          alt={alt}
          width={width}
          height={height}
          effect="blur"
          placeholder={<Skeleton w={`${width}px`} h={`${height}px`} />}
          style={{
            ...style,
            ...imageFit,
          }}
          onError={() => <DefaultImage width={width} height={height} style={style} />}
        />
      )}
    </Flex>
  );
};

type DefaultImageProps = Pick<Props, 'width' | 'height' | 'style'>;
const DefaultImage: FC<DefaultImageProps> = ({ width, height, style }) => {
  return (
    <Image
      src={DefaultIcon}
      alt="App Image"
      minWidth={`${width}px`}
      minHeight={`${height}px`}
      width={`${width}px`}
      height={`${height}px`}
      style={{
        ...style,
        borderRadius: '4px',
      }}
    />
  );
};

export default memo(AppImage);
