import { createContext, FC, useEffect, useState } from 'react';

export interface ContextValue {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  login: () => void;
  signOut: () => void;
}

const defaultValue: ContextValue = {
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  login: () => {},
  signOut: () => {},
};

export const AuthContext = createContext(defaultValue);

interface Props {
  children: JSX.Element;
  music: MusicKit.MusicKitInstance;
}

const AuthProvider: FC<Props> = ({ children, music }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(music?.isAuthorized ?? false);
  }, [music]);

  const login = async (): Promise<void> => {
    const userToken = await music.authorize();
    console.log('usertoken', userToken);
    setIsLoggedIn(music?.isAuthorized ?? false);
    window.location.reload();
  };
  const signOut = async (): Promise<void> => {
    setIsLoggedIn(false);
    await music?.unauthorize();
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, login, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
