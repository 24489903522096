export const useKeyboard = (): void => {
  // const music = MusicKit.getInstance()
  // document.addEventListener('selectstart', (e) => {
  //   e.preventDefault();
  // });

  document.addEventListener('keydown', (e) => {
    if (e.code === 'Space' && e.target === document.body) {
      e.preventDefault();
    }
  });

  // useHotkeys('Space', playOrStop);

  // useHotkeys('right', (e) => {
  //   music.skipToNextItem();
  // });
  // useHotkeys('left', (e) => {
  //   music.skipToPreviousItem();
  // });

  if (window.__TAURI_IPC__) {
    // await register('MediaPlayPause', playOrStop);
    // await unregister(keyboard.MediaTrackNext.name);
    // await unregister(keyboard.MediaTrackPrevious.name);
    // await register(keyboard.MediaTrackNext.name, (e) => {
    //   music.skipToNextItem();
    // });
    // await register(keyboard.MediaTrackPrevious.name, (e) => {
    //   music.skipToPreviousItem();
    // });
  }
};

// const keyboard = {
//   MediaPlayPause: {
//     key: 'F8',
//     name: 'MediaPlayPause'
//   },
//   MediaTrackPrevious: {
//     key: 'F7',
//     name: 'MediaTrackPrevious'
//   },
//   MediaTrackNext: {
//     key: 'F9',
//     name: 'MediaTrackNext'
//   }
// }
