import { Box, Divider, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import Playback from './Playback';
import Volume from './Volume';

const PlaybackControlls: FC = () => {
  return (
    <Box pos="sticky" bottom={0} left={0} w="full">
      <Divider />
      <Flex flexDir="column" justify="center" align="center" py="6">
        <Playback />
        <Volume />
      </Flex>
    </Box>
  );
};

export default PlaybackControlls;
