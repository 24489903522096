import { api } from '@/lib/api';
import { Track } from '@/types/track.type';
import useSWR, { SWRResponse } from 'swr';

interface RecentTrack {
  next: string;
  data: Track[];
}
export const get = (): Promise<RecentTrack> => api.get('v1/me/recent/played').json<RecentTrack>();

export const useRecentTracks = (): SWRResponse<RecentTrack, any> => {
  return useSWR('recent-played', get, {
    revalidateOnFocus: true,
  });
};
