import App from '@/App';
import { albumRoutes } from '@/features/album/routes/AlbumRoute';
import { artistRoutes } from '@/features/artist/routes/ArtistRoute';
import { chartRoutes } from '@/features/chart/routes/ChartRoute';
import { historyRoutes } from '@/features/history/routes/HistoryRoute';
import { playlistRoutes } from '@/features/playlist/routes/PlaylistRoute';
import { recommendationRoutes } from '@/features/recommendation/routes/RecommendationRoute';
import { searchRoutes } from '@/features/search/routes/SaerchRoute';
import { settingRoutes } from '@/features/setting/routes/SettingRoute';
import { stationRoutes } from '@/features/station/routes/StationRoute';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      ...recommendationRoutes,
      {
        path: '/settings/*',
        children: settingRoutes,
      },
      {
        path: '/playlists/*',
        children: playlistRoutes,
      },
      {
        path: '/history/*',
        children: historyRoutes,
      },
      {
        path: '/artists/*',
        children: artistRoutes,
      },
      {
        path: '/search/*',
        children: searchRoutes,
      },
      {
        path: '/albums/*',
        children: albumRoutes,
      },
      {
        path: '/stations/*',
        children: stationRoutes,
      },
      {
        path: '/charts/*',
        children: chartRoutes,
      },
    ],
  },
]);
