import AppImage from '@/components/common/AppImage';
import Cover from '@/components/common/Cover';
import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSearchResultDetail } from '../../api/useSearchResultDetail';

const Songs: FC = () => {
  const queryParams = useQueryParams();
  const term = queryParams.get('term') ?? '';

  const {
    data: searchResult,
    isValidating,
    size,
    setSize,
  } = useSearchResultDetail({
    types: ['songs'],
    term,
  });

  const songs = searchResult?.flatMap?.((x) => x?.results?.songs?.data) ?? [];
  const next = searchResult?.flatMap?.((x) => x)?.at(-1)?.results?.songs?.next;

  const hasMore = next && !isValidating;
  // const last = !next && !isValidating

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <>
      <Flex flexDir="column">
        <Cover my="10">
          <Flex justify="space-evenly" wrap="wrap" py="4">
            {songs?.map((x) => (
              <Link key={x.id} to={getLink(x.id, x.type, x.attributes.url)}>
                <Box p="4">
                  <AppImage
                    artwork={x.attributes.artwork}
                    alt={x.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Text w={200} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                    {x.attributes.name}
                  </Text>
                </Box>
              </Link>
            ))}
          </Flex>
        </Cover>
      </Flex>

      <Box ref={ref} pb="4">
        {isValidating && (
          <Box my="4">
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Songs;
