import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdClose } from 'react-icons/md';

type Props = IconButtonProps;

const AppCloseIcon: FC<Props> = (props) => {
  return <IconButton icon={<MdClose />} variant="ghost" _hover={{ bgColor: 'red' }} {...props} />;
};

export default AppCloseIcon;
