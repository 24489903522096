import AppImage from '@/components/common/AppImage';
import { getLink } from '@/utils/getLink';
import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useHeavyRotation } from '../api/useHeavyRotation';

const HeavyRotation: FC = () => {
  const { data } = useHeavyRotation();

  return (
    <Flex>
      {data?.data?.map((x) => (
        <Link key={x.id} to={getLink(x.id, x.type)}>
          <AppImage
            artwork={x.attributes.artwork}
            alt={x.attributes.name}
            width={200}
            height={200}
          />
          <p>{x.attributes.name}</p>
        </Link>
      ))}
    </Flex>
  );
};

export default HeavyRotation;
