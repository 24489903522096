import AppImage from '@/components/common/AppImage';
import { getLink } from '@/utils/getLink';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRecommendations } from '../api/useRecommendations';

const Recommendations: FC = () => {
  const { data: recommendations } = useRecommendations();

  return (
    <Stack>
      {recommendations?.data?.map((x) => (
        <Box key={x.id}>
          <Text>{x.attributes.title.contentIds}</Text>
          <Text>{x.attributes.title.stringForDisplay}</Text>
          <Text>{x.attributes.kind}</Text>
          <Text>{x.attributes.nextUpdateDate.toString()}</Text>
          <Box>
            {x.relationships.contents.data.map((y) => (
              <Link key={y.id} to={getLink(y.id, y.type)}>
                <Flex>
                  <AppImage
                    artwork={y.attributes.artwork}
                    width={200}
                    height={200}
                    alt={y.attributes.name}
                  />
                  <Text>{y.type}</Text> -<Text>{y.attributes.name}</Text> -
                  <Text>{y.attributes.artistName}</Text>
                </Flex>
              </Link>
            ))}
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default Recommendations;
