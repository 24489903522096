import { useCallback, useEffect, useState } from 'react';

import { usePlayerEventCallback } from '@/hooks/usePlayerEventCallback';

const getQueueItems = (): MusicKit.MediaItem[] => {
  const music = MusicKit.getInstance();
  const items = music?.queue?.items ?? [];

  return items;
};

export const useQueueItems = (): MusicKit.MediaItem[] => {
  const [value, setValue] = useState<MusicKit.MediaItem[]>([]);

  useEffect(() => {
    const unsubscribe = setValue(getQueueItems());
    return unsubscribe;
  }, []);

  const update = useCallback(() => {
    setValue(getQueueItems());
  }, []);

  usePlayerEventCallback(MusicKit.Events.nowPlayingItemDidChange, update, []);
  usePlayerEventCallback(MusicKit.Events.queueItemsDidChange, update, []);
  usePlayerEventCallback(MusicKit.Events.queuePositionDidChange, update, []);

  return value;
};
