import { useSetRecoilState } from 'recoil';
import { Middleware } from 'swr';

import { isLoadingState } from '@/state/loading';

export const swrMiddleware: Middleware = (useSWRNext) => (key, fetcher, config) => {
  const res = useSWRNext(key, fetcher, config);
  const setIsLoading = useSetRecoilState(isLoadingState);
  setIsLoading(res.isLoading);
  return res;
};
