import { FC } from 'react';
import { MdVolumeUp } from 'react-icons/md';

interface Props {
  volume: number;
}
const VolumeIcon: FC<Props> = ({ volume }) => {
  // if (volume > 0.5) {
  //   return <MdVolumeUp width='22px' size='22px' />;
  // } else if (volume < 0.5 && volume > 0.2) {
  //   return <MdVolumeDown width='22px' size='22px' />;
  // } else if (volume === 0) {
  //   return <MdVolumeOff />;
  // }
  // return <MdVolumeMute width='22px' size='22px' />;
  return <MdVolumeUp width="22px" size="22px" />;
};

export default VolumeIcon;
