import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import useSWR, { SWRResponse } from 'swr';

interface ArtistAlbums {
  data: Daum[];
  meta: Meta;
}

export interface Daum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
}

export interface Attributes {
  trackCount: number;
  genreNames: string[];
  releaseDate?: string;
  name: string;
  artistName: string;
  artwork: Artwork;
  playParams: PlayParams;
  dateAdded: string;
}

export interface Meta {
  total: number;
}

interface Options {
  limit?: number;
  offset?: number;
}

// TODO: offsetでページネーション
const get = (id: string, options?: Options): Promise<ArtistAlbums> => {
  return api
    .get(`v1/me/library/artists/${id}/albums`, {
      searchParams: {
        limit: options?.limit ?? 100,
        offset: options?.offset ?? 0,
      },
    })
    .json<ArtistAlbums>();
};
export const useMyArtistDetail = (
  id: string,
  options?: Options
): SWRResponse<ArtistAlbums, any> => {
  return useSWR(`artists-detail-${id}`, () => get(id, options));
};

// const getKey = (pageIndex: number, prev: ArtistAlbums, id: string) => {
//   if (prev && !prev.next) {
//     return null;
//   }

//   const offset = pageIndex * 100;

//   return { keyId: id, offset };
// };
