import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, ChakraProps, Flex } from '@chakra-ui/react';

type Props = {
  title: string;
  to: string;
  icon: JSX.Element;
} & ChakraProps;

const IconLink: FC<Props> = ({ title, to, icon, ...props }) => {
  return (
    <Link to={to}>
      <Box {...props}>
        <Flex alignItems="center" w="full">
          <Box mr="2">{icon}</Box>
          <Box>{title}</Box>
        </Flex>
      </Box>
    </Link>
  );
};

export default IconLink;
