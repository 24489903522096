import { FC, lazy } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';

const MyPlaylists = lazy(() => import('@/features/playlist/components/MyPlaylists'));
const MyPlaylistDetail = lazy(() => import('@/features/playlist/components/MyPlaylistDetail'));
const PlaylistDetail = lazy(() => import('@/features/playlist/components/PlaylistDetail'));
const Playlists = lazy(() => import('@/features/playlist/components/Playlists'));

const PlaylistRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Playlists />} />
      <Route path="/:id" element={<PlaylistDetail />} />
      <Route path="/library/" element={<MyPlaylists />} />
      <Route path="/library/:id" element={<MyPlaylistDetail />} />
    </Routes>
  );
};

export default PlaylistRoute;

export const playlistRoutes: RouteObject[] = [
  {
    index: true,
    element: <Playlists />,
  },
  {
    path: ':id',
    element: <PlaylistDetail />,
  },
  {
    path: 'library/',
    element: <MyPlaylists />,
  },
  {
    path: 'library/:id',
    element: <MyPlaylistDetail />,
  },
];
