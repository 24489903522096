import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { BiWindows } from 'react-icons/bi';

type Props = IconButtonProps;

const AppMinMaxIcon: FC<Props> = (props) => {
  return <IconButton icon={<BiWindows />} variant="ghost" {...props} />;
};

export default AppMinMaxIcon;
