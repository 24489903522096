import { ShuffleMode, shuffleModeState } from '@/state/playback';
import { IconButton } from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import ShuffleModeIcon from './ShuffleModeIcon';

const ShuffleIcon: FC = () => {
  const [shuffleMode, setShuffleMode] = useRecoilState(shuffleModeState);
  const music = useMemo(() => MusicKit.getInstance(), []);

  const isOff = shuffleMode === ShuffleMode.off;

  return (
    <IconButton
      onClick={() => {
        if (isOff) {
          setShuffleMode(ShuffleMode.songs);
          music.shuffleMode = ShuffleMode.songs;
        } else {
          setShuffleMode(ShuffleMode.off);
          music.shuffleMode = ShuffleMode.off;
        }
      }}
      icon={<ShuffleModeIcon color={isOff ? undefined : 'app.200'} />}
      aria-label="shuffle item"
      variant="ghost"
      rounded="full"
    />
  );
};

export default ShuffleIcon;
