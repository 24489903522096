import { FC, Suspense, useEffect, useMemo } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useDisclosure } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import Layout from '@/components/Layout';
import LoginModal from '@/components/LoginModal';
import { MODE, SENTRY_DSN } from '@/constants/env';
import { useAppSettings } from '@/hooks/useAppSettings';
import { useAuth } from '@/hooks/useAuth';
import { useKeyboard } from '@/hooks/useKeyboard';
import { playbackState, repeatModeState, shuffleModeState } from '@/state/playback';
import { saveVolume } from '@/utils/volumeUtil';

import '@/styles/main.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

if (MODE === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: MODE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App: FC = () => {
  const { isLoggedIn } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname, search } = useLocation();

  const music = useMemo(() => MusicKit.getInstance(), []);

  const setPlayback = useSetRecoilState(playbackState);
  const setRepeatMode = useSetRecoilState(repeatModeState);
  const setShuffleMode = useSetRecoilState(shuffleModeState);

  useAppSettings();
  useKeyboard();
  useEffect(() => localStorage.setItem('app-path', `${pathname}${search}`), []);

  useEffect(() => {
    music.addEventListener(MusicKit.Events.playbackVolumeDidChange, saveVolume);
    music.addEventListener(MusicKit.Events.playbackStateDidChange, () => {
      setPlayback(music.playbackState as number);
      setRepeatMode(music.repeatMode);
      setShuffleMode(music.shuffleMode);
    });

    return () => {
      music.removeEventListener(MusicKit.Events.playbackVolumeDidChange);
      music.removeEventListener(MusicKit.Events.playbackStateDidChange);
    };
  }, []);

  useEffect(() => {
    isLoggedIn ? onClose() : onOpen();
  }, [isLoggedIn, onClose, onOpen]);

  if (!isLoggedIn) {
    return <LoginModal open={isOpen} />;
  }

  return (
    <>
      <Suspense fallback={<></>}>
        <Layout>
          <Outlet />
        </Layout>
      </Suspense>
      <ScrollRestoration />
    </>
  );
};

export default App;
