import { appWindow } from '@tauri-apps/api/window';
import { FC } from 'react';

import { Box, Divider, Flex, Progress, Show } from '@chakra-ui/react';
import { MdFormatListBulleted } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import Drawer from '@/components/common/Drawer';
import Playback from '@/components/common/Playback';
import Volume from '@/components/common/Volume';
import MediaBar from '@/components/header/MediaBar';
import AppCloseIcon from '@/components/icon/AppCloseIcon';
import AppMinimizeIcon from '@/components/icon/AppMinimizeIcon';
import AppMinMaxIcon from '@/components/icon/AppMinMaxIcon';
import GoBackIcon from '@/components/icon/GoBackIcon';
import GoForwardIcon from '@/components/icon/GoForwardIcon';
import { breakpoint } from '@/constants/style';
import QueueList from '@/features/queue/components/QueueList';
import { isLoadingState } from '@/state/loading';
import { useRecoilValue } from 'recoil';

const Header: FC = () => {
  const navigate = useNavigate();
  const isLoading = useRecoilValue(isLoadingState);

  return (
    <>
      <Flex
        data-tauri-drag-region
        justify="space-between"
        align="center"
        maxW="full"
        w="full"
        h="60px"
        maxH="60px"
        zIndex={999999}
      >
        <Flex data-tauri-drag-region justify="space-between" align="center" ml="2">
          <GoBackIcon onClick={() => navigate(-1)} aria-label="back" />
          <GoForwardIcon onClick={() => navigate(1)} aria-label="forward" />

          <Show breakpoint={`(min-width: ${breakpoint}px)`}>
            <Playback />
          </Show>
        </Flex>

        <Flex data-tauri-drag-region>
          <Box>
            <Divider orientation="vertical" color="red" />
          </Box>
          <Box data-tauri-drag-region mx={[2, 2, 4]}>
            <MediaBar />
          </Box>
          <Box>
            <Divider orientation="vertical" color="red" />
          </Box>
        </Flex>

        <Flex data-tauri-drag-region h="60px" align="center">
          <Show breakpoint={`(min-width: ${breakpoint}px)`}>
            <Volume />
          </Show>
          <Box px={4}>
            <Drawer
              buttonChildren={<MdFormatListBulleted size="20px" aria-label="Queue List" />}
              placement="right"
              size="sm"
            >
              <QueueList />
            </Drawer>
          </Box>
          {window.__TAURI_IPC__ && (
            <>
              <AppMinimizeIcon
                onClick={() => appWindow.minimize()}
                aria-label="minimize app"
                w="48px"
                h="60px"
              />
              <AppMinMaxIcon
                onClick={() => appWindow.toggleMaximize()}
                aria-label="minimize / maximize icon"
                w="48px"
                h="60px"
              />
              <AppCloseIcon
                onClick={() => appWindow.close()}
                aria-label="close app"
                w="48px"
                h="60px"
              />
            </>
          )}
        </Flex>
      </Flex>

      <Progress
        size="xs"
        h="1px"
        colorScheme="red"
        isIndeterminate={isLoading}
        isAnimated
        hasStripe
        zIndex={999999}
      />
    </>
  );
};

export default Header;
