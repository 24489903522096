import AppImage from '@/components/common/AppImage';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { getAlbumId } from '@/utils/stringUtil';
import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSearchResult } from '../api/useSearchResult';

const SearchResult: FC = () => {
  const queryParams = useQueryParams();
  const term = queryParams.get('term') ?? '';

  const { data } = useSearchResult({
    types: [
      'activities',
      'albums',
      'apple-curators',
      'artists',
      'curators',
      'music-videos',
      'playlists',
      'record-labels',
      'songs',
      'stations',
    ],
    term,
    searchWith: 'topResults',
  });

  const topResults = data?.results?.topResults?.data ?? [];
  const albums = data?.results?.albums?.data ?? [];
  const artists = data?.results?.artists?.data ?? [];
  const songs = data?.results?.songs?.data ?? [];

  return (
    <Box>
      {topResults?.length && <Text fontSize="xl">Top result</Text>}
      {topResults?.map((x) => (
        <Link key={x.id} to={getLink(x.id, x.type, x.attributes.url)}>
          <Flex>
            <AppImage
              artwork={x.attributes.artwork}
              alt={x.attributes.name}
              width={200}
              height={200}
            />
            <Text>{x.attributes.name}</Text>
            <Text>{x.href}</Text>
            <Text>{x.type}</Text>
          </Flex>
        </Link>
      ))}

      <Divider />

      {artists?.length && <Text fontSize="xl">アーティスト</Text>}
      <Flex flexDir="row">
        {artists?.map((x) => (
          <Link key={x.id} to={getLink(x.id, x.type)}>
            <Box mx="2">
              <Avatar
                src={
                  x.attributes?.artwork?.url
                    ? MusicKit.formatArtworkURL(x.attributes.artwork, 44, 44)
                    : ''
                }
                w="90px"
                h="90px"
              />
              <Text>{x.attributes.name}</Text>
            </Box>
          </Link>
        ))}
      </Flex>

      <Divider />

      {songs?.length && (
        <Flex justify="space-between">
          <Text fontSize="xl">曲</Text>
          <Link to={`/search/songs?term=${term}`}>
            <Text color="app.100">All Songs</Text>
          </Link>
        </Flex>
      )}
      {songs?.map?.((x) => (
        <Link key={x.id} to={`/albums/${getAlbumId(x.attributes.url) ?? ''}`}>
          <Flex flexDir="column">
            <AppImage
              artwork={x.attributes.artwork}
              alt={x.attributes.name}
              width={200}
              height={200}
            />

            <Text>{x.attributes.name}</Text>
            <Text>{getAlbumId(x.attributes.url)}</Text>
            <Text>{x.id}</Text>
          </Flex>
        </Link>
      ))}

      <Divider />

      {albums?.length && (
        <Flex justify="space-between">
          <Text fontSize="xl">アルバム</Text>
          <Link to={`/search/albums?term=${term}`}>
            <Text color="app.100">All Albums</Text>
          </Link>
        </Flex>
      )}
      {albums?.map((x) => (
        <Link key={x.id} to={getLink(x.id, x.type)}>
          <Flex>
            <AppImage
              artwork={x.attributes.artwork}
              alt={x.attributes.name}
              width={200}
              height={200}
            />

            <Text>{x.attributes.name}</Text>
            <Text>{x.href}</Text>
          </Flex>
        </Link>
      ))}
    </Box>
  );
};

export default SearchResult;
