import { FC, useEffect, useRef } from 'react';

import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { useQueryParams } from '@/hooks/useQueryParams';
import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useMyArtists } from '../api/useMyArtists';
import MyArtistWorks from './MyArtistWorks';

const MyArtistList: FC = () => {
  const { data, isValidating, size, setSize } = useMyArtists();
  const artists = data?.flatMap((x) => x.data) ?? [];

  const next = data?.flatMap?.((x) => x)?.at(-1)?.next;
  const hasMore = !!next && !isValidating;

  const params = useQueryParams();
  const id = params.get('id');

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <Flex overflow="hidden" h="full">
      <Box
        overflowY="scroll"
        w={['200px', '250px', '250px', '350px']}
        minW={['200px', '250px', '250px', '350px']}
        h="full"
        pt="4"
      >
        <Flex flexDir="column">
          {artists.map((x) => {
            const artist = x.relationships?.catalog?.data?.[0];

            return (
              <Link key={x.id} to={`/artists/library?id=${x.id}`}>
                <Flex
                  px={[2, 2, 2, 4]}
                  py="1"
                  ml="2"
                  my="0.9"
                  align="center"
                  bgColor={x.id === id ? 'selected' : undefined}
                  _hover={{ bgColor: 'selected' }}
                  borderRadius="8px"
                >
                  <Avatar
                    mr="2"
                    src={
                      artist?.attributes?.artwork
                        ? MusicKit.formatArtworkURL(artist.attributes.artwork, 44 * 4, 44 * 4)
                        : ''
                    }
                  />
                  <Text>{x.attributes.name}</Text>
                </Flex>
              </Link>
            );
          })}
        </Flex>

        <Box ref={ref} pb="4">
          {isValidating && (
            <Box my="4">
              <Loading />
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        <Divider orientation="vertical" />
      </Box>

      {id && <MyArtistWorks />}
    </Flex>
  );
};

export default MyArtistList;
