import { Alert, AlertIcon, Button } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  refetch?: () => void;
}
const ErrorAlert: FC<Props> = ({ refetch }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      Failed To Load Content
      {refetch != null && <Button onClick={refetch}>Reload</Button>}
    </Alert>
  );
};

export default ErrorAlert;
