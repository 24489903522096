import AppImage from '@/components/common/AppImage';
import Cover from '@/components/common/Cover';
import Loading from '@/components/Loading';
import { useIntersection } from '@/hooks/useIntersection';
import { getLink } from '@/utils/getLink';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRecentAddedItemList } from '../api/useRecentAddedItemList';

const RecentAddedItemList: FC = () => {
  const { data, setSize, size, isValidating } = useRecentAddedItemList();
  const recentAddedItems = data?.flatMap((x) => x.data ?? []);

  const next = data?.flatMap?.((x) => x)?.at(-1)?.next;
  const hasMore = !!next && !isValidating;

  const ref = useRef<HTMLDivElement>(null);
  const isIntersection = useIntersection(ref);
  useEffect(() => {
    if (isIntersection && hasMore) {
      setSize(size + 1);
    }
  }, [hasMore, isIntersection, setSize, size]);

  return (
    <Stack>
      <Cover my="10">
        <Flex justify="space-evenly" wrap="wrap" py="4">
          {recentAddedItems?.map((x) => (
            <Link key={x.id} to={getLink(x.id, x.type)}>
              <Box p="4">
                <AppImage
                  artwork={x.attributes.artwork}
                  alt={x.attributes.name}
                  width={200}
                  height={200}
                />
                <Text width={200} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  {x.attributes.name}
                </Text>
                <Link to={'/artists/library'}>
                  <Text width={200} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                    {x.attributes.artistName}
                  </Text>
                </Link>
              </Box>
            </Link>
          ))}
        </Flex>
      </Cover>

      <Box ref={ref}>
        {isValidating && (
          <Box my="4">
            <Loading />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default RecentAddedItemList;
