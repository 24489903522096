import { FC } from 'react';
import { Route, RouteObject, Routes } from 'react-router-dom';
import SettingList from '../components/SettingList';

const SettingRoute: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SettingList />} />
    </Routes>
  );
};

export default SettingRoute;

export const settingRoutes: RouteObject[] = [
  {
    index: true,
    element: <SettingList />,
  },
];
