import { Box, Divider, Flex, Show } from '@chakra-ui/react';
import { FaRegClock } from 'react-icons/fa';
import { GiMicrophone } from 'react-icons/gi';
import { RiPlayListFill } from 'react-icons/ri';

import { breakpoint } from '@/constants/style';
import SearchBar from '@/features/search/components/SearchBar';
import { FC, memo } from 'react';
import { MdPlayCircleOutline, MdSettings } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import PlaybackControlls from '../common/PlaybackControlls';
import IconLink from '../icon/IconLink';
import SideBarPlaylists from './SideBarPlaylists';

const links = [
  {
    title: 'Listen Now',
    to: '/',
    icon: <MdPlayCircleOutline size="20px" />,
  },

  {
    title: 'Recent',
    to: '/history/recent-played',
    icon: <FaRegClock size="20px" />,
  },
  {
    title: 'Playlists',
    to: '/playlists/library',
    icon: <RiPlayListFill size="20px" />,
  },
  {
    title: 'Heavy Rotation',
    to: '/history/heavy-rotation',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: 'Artists',
    to: '/artists/library',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: 'Albums',
    to: '/albums/library',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: 'Recently Added',
    to: '/history/recently-added',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: 'Chart',
    to: '/charts',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: 'Stations',
    to: '/stations',
    icon: <GiMicrophone size="20px" />,
  },
  {
    title: '設定',
    to: '/settings',
    icon: <MdSettings size="20px" />,
  },
];

const SideBar: FC = () => {
  const location = useLocation();

  return (
    <>
      <Flex flexDir="column" maxW="full" h="full" bgColor="appBg.100">
        <Box px="3" pt="4" mt="2">
          <SearchBar />
        </Box>

        <Box w="full" h="full" pt="4" overflowY="scroll">
          {links.map((x) => (
            <IconLink
              key={x.to}
              title={x.title}
              to={x.to}
              icon={x.icon}
              px="4"
              py="3"
              my="1"
              ml="2"
              borderRadius="md"
              color={location.pathname === x.to ? 'white' : undefined}
              bgColor={location.pathname === x.to ? 'selected' : undefined}
              _hover={{ bgColor: 'selected' }}
            />
          ))}

          <Divider my="4" />

          <SideBarPlaylists />
        </Box>

        <Show breakpoint={`(max-width: ${breakpoint}px)`}>
          <Box backdropBlur="xl">
            <PlaybackControlls />
          </Box>
        </Show>
      </Flex>

      <Box>
        <Divider orientation="vertical" />
      </Box>
    </>
  );
};

export default memo(SideBar);
