import { api } from '@/lib/api';
import { SearchSuggestions } from '@/types/search-suggestion.type';
import { getStorefront } from '@/utils/storefront';
import useSWR, { SWRResponse } from 'swr';
import { SearchTypes } from '../types/search-types';

export const get = (term: string, types: SearchTypes[]): Promise<SearchSuggestions> => {
  return api
    .get(`v1/catalog/${getStorefront()}/search/suggestions`, {
      searchParams: {
        term,
        kinds: 'terms,topResults',
        types: types.join(',') ?? '',
        limit: 10,
      },
    })
    .json<SearchSuggestions>();
};

export const useSearchSuggestions = (
  term: string,
  types: SearchTypes[]
): SWRResponse<SearchSuggestions | null, any> => {
  return useSWR(`get-suggestions-${term}`, () => (term ? get(term, types) : null), {});
};
