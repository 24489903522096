import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  fonts: {
    body: '-apple-system, Arial, sans-serif',
  },
  colors: {
    app: {
      100: '#fa586a',
      200: '#d60017',
    },
    selected: '#323232',
    appBg: {
      100: '#212121',
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('gray.100', '#1a181a')(props),
      },
    }),
  },
  // components: {
  //   Button: {
  //     baseStyle: {
  //       bgColor: '#fa586a',
  //       fontWeight: 'bold',
  //       _hover: {
  //         _disabled: {
  //           bg: '#2477b3',
  //         },
  //       },
  //     },
  //     variants: {
  //       bg: '#2B6CB0',
  //       _active: {
  //         bg: '#2477b3',
  //         transform: 'scale(0.98)',
  //         borderColor: '#2477b3',
  //       },
  //     },
  // },
  // },
});
