import { FC, ReactNode, useRef } from 'react';

import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerProps,
  useDisclosure,
} from '@chakra-ui/react';

type DP = Omit<DrawerProps, 'isOpen' | 'onClose'>;

type Props = {
  children: ReactNode;
  buttonChildren: ReactNode;
} & DP;

const Drawer: FC<Props> = ({ children, buttonChildren, ...drawerProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  const toggleDrawer = (): void => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Button ref={btnRef} onClick={toggleDrawer} variant="ghost">
        {buttonChildren}
      </Button>

      <ChakraDrawer
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        returnFocusOnClose={false}
        placement="right"
        size="full"
        {...drawerProps}
      >
        <DrawerContent mt="70px" mr="2" mb="4" borderRadius="lg" bgColor="appBg.100">
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};

export default Drawer;
