import { api } from '@/lib/api';
import { getStorefront } from '@/utils/storefront';
import useSWR, { SWRResponse } from 'swr';
import { ArtistDetail } from '../types/artist-detail.type';

type ViewType =
  | 'appears-on-albums'
  | 'compilation-albums'
  | 'featured-albums'
  | 'featured-music-videos'
  | 'featured-playlists'
  | 'full-albums'
  | 'latest-release'
  | 'live-albums'
  | 'similar-artists'
  | 'singles'
  | 'top-music-videos'
  | 'top-songs';

const get = (id: string, views: ViewType[]): Promise<ArtistDetail> =>
  api
    .get(`v1/catalog/${getStorefront()}/artists/${id}`, {
      searchParams: {
        views: views.join(','),
      },
    })
    .json<ArtistDetail>();

export const useArtistDetail = (
  id: string,
  views: ViewType[]
): SWRResponse<ArtistDetail | null, any> => {
  return useSWR(`artist-${id}-${views.join(',')}`, () => (id ? get(id, views) : null));
};
