export const getOffset = (url: string): number => {
  const paramKey = '?offset=';
  const index = url.indexOf(paramKey);
  const param = url.substring(index);
  const offset = param.replace(paramKey, '');

  return parseInt(offset ?? '', 10) || 0;
};

export const getAlbumId = (url?: string): string | undefined => {
  return url?.substring(url?.lastIndexOf('/') + 1);
};
