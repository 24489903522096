import useSWR, { SWRResponse } from 'swr';

import { api } from '@/lib/api';
import { Artwork } from '@/types/artwork.type';
import { PlayParams } from '@/types/play-params.type';
import { Preview } from '@/types/preview.type';
import { getStorefront } from '@/utils/storefront';

interface Attributes {
  albumName: string;
  genreNames: string[];
  trackNumber: number;
  releaseDate: string;
  durationInMillis: number;
  isrc: string;
  artwork: Artwork;
  composerName: string;
  url: string;
  playParams: PlayParams;
  discNumber: number;
  isAppleDigitalMaster: boolean;
  hasLyrics: boolean;
  name: string;
  previews: Preview[];
  artistName: string;
}

interface Attributes2 {
  albumName: string;
  discNumber: number;
  genreNames: string[];
  hasLyrics: boolean;
  trackNumber: number;
  releaseDate: string;
  durationInMillis: number;
  name: string;
  artistName: string;
  artwork: Artwork;
  playParams: PlayParams;
}

interface Attributes3 {
  name: string;
}

interface Datum3 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes3;
}

interface Artists {
  href: string;
  data: Datum3[];
}

interface Relationships2 {
  artists: Artists;
}

interface Datum2 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes2;
  relationships: Relationships2;
}

interface Library {
  href: string;
  data: Datum2[];
}

interface Attributes4 {
  genreNames: string[];
  name: string;
  artwork: Artwork;
  url: string;
}

interface Datum5 {
  id: string;
  type: string;
  href: string;
}

interface Albums {
  href: string;
  next: string;
  data: Datum5[];
}

interface Attributes5 {
  parentName: string;
  name: string;
  parentId: string;
}

interface Datum6 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes5;
}

interface Genres {
  href: string;
  data: Datum6[];
}

interface Relationships3 {
  albums: Albums;
  genres: Genres;
}

interface Datum4 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes4;
  relationships: Relationships3;
}

interface Artists2 {
  href: string;
  data: Datum4[];
}

interface Attributes6 {
  parentName: string;
  name: string;
  parentId: string;
}

interface Datum7 {
  id: string;
  type: string;
  href: string;
  attributes: Attributes6;
}

interface Genres2 {
  href: string;
  data: Datum7[];
}

interface Relationships {
  library: Library;
  artists: Artists2;
  genres: Genres2;
}

interface Datum {
  id: string;
  type: string;
  href: string;
  attributes: Attributes;
  relationships: Relationships;
}

interface AlbumDetailTracks {
  data: Datum[];
}

export const get = (id: string): Promise<AlbumDetailTracks> => {
  return api
    .get(`v1/catalog/${getStorefront()}/albums/${id}/tracks`, {
      searchParams: {
        views: 'related-albums,appears-on',
        include: 'library',
      },
    })
    .json<AlbumDetailTracks>();
};

export const useAlbumDetailTracks = (id: string): SWRResponse<AlbumDetailTracks, any> => {
  return useSWR(`album-detail-${id}-tracks`, () => get(id));
};
