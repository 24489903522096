import useSWR, { SWRResponse } from 'swr';

import { api } from '@/lib/api';
import { SearchResult } from '@/types/search-result.type';
import { getStorefront } from '@/utils/storefront';
import { SearchTypes, SearchWith } from '../types/search-types';

interface Params {
  types: SearchTypes[];
  term: string;
  searchWith?: SearchWith;
  limit?: number;
  offset?: number;
}

const defaultOptions = {
  limit: 25,
  offset: 0,
};

export const get = ({ types, term, searchWith, limit, offset }: Params): Promise<SearchResult> => {
  return api
    .get(`v1/catalog/${getStorefront()}/search`, {
      searchParams: {
        types: types.join(','),
        term,
        with: searchWith ?? '',
        limit: limit ?? defaultOptions.limit,
        offset: offset ?? defaultOptions.offset,
      },
    })
    .json<SearchResult>();
};

export const useSearchResult = (params: Params): SWRResponse<SearchResult | null, any> => {
  return useSWR(`search-result-${params.term}`, () => (params.term ? get({ ...params }) : null));
};
