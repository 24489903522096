import { api } from '@/lib/api';
import useSWR, { SWRResponse } from 'swr';

interface Meta {
  total: number;
}

// TODO: ここなおす
export interface MyPlayLists {
  data: any[];
  meta: Meta;
}

const get = (): Promise<MyPlayLists> =>
  api
    .get('v1/me/library/playlists', {
      searchParams: {
        include: 'catalog',
      },
    })
    .json<MyPlayLists>();

export const useMyPlaylists = (): SWRResponse<MyPlayLists, any> => {
  return useSWR('my-playlists', get, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};
