import AppImage from '@/components/common/AppImage';
import Cover from '@/components/common/Cover';
import { useQueryParams } from '@/hooks/useQueryParams';
import { getLink } from '@/utils/getLink';
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useMyArtistDetail } from '../api/useMyArtistDetail';

const MyArtistWorks: FC = () => {
  const params = useQueryParams();
  const id = params.get('id') ?? '';

  const { data: artistWorks } = useMyArtistDetail(id, {
    limit: 100,
  });

  const artistMeta = artistWorks?.data?.find?.((x) => x.id !== undefined);

  const hasArtistWorks = artistWorks?.data?.length && artistWorks.data.length > 0;

  return (
    <Flex flexDir="column" overflowY="scroll" w="full">
      {artistMeta?.attributes?.artistName && (
        <Flex justify="center" w="full">
          <Flex flexDir="column" justify="flex-start" w="90%" py="6">
            <Heading>{artistMeta?.attributes?.artistName}</Heading>
            <Divider my="4" />
          </Flex>
        </Flex>
      )}

      {hasArtistWorks && (
        <Cover my="10">
          <Flex justify="space-evenly" wrap="wrap" py="4">
            {artistWorks?.data?.map((x) => (
              <Link key={x.id} to={getLink(x.id, x.type)}>
                <Box p="4">
                  <AppImage
                    artwork={x.attributes.artwork}
                    alt={x.attributes.name}
                    width={200}
                    height={200}
                  />
                  <Text width={200} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                    {x.attributes.name}
                  </Text>
                  <Link to={'/artists/library'}>
                    <Text width={200} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {x.attributes.artistName}
                    </Text>
                  </Link>
                </Box>
              </Link>
            ))}
          </Flex>
        </Cover>
      )}
    </Flex>
  );
};

export default MyArtistWorks;
