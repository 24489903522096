import { api } from '@/lib/api';
import { getStorefront } from '@/utils/storefront';
import useSWR, { SWRResponse } from 'swr';

const get = (id: string): Promise<any> =>
  api.get(`v1/catalog/${getStorefront()}/stations/${id}`).json<any>();

export const useStationDetail = (id: string): SWRResponse<any, any> => {
  return useSWR(`station-${id}`, () => get(id));
};
