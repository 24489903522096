import { useMemo } from 'react';
import { useNowPlayingItem } from './useNowPlayingItem';

export const usePlayerRef = (): HTMLAudioElement | null => {
  const nowPlayingItem = useNowPlayingItem();

  const playerElement = useMemo(() => {
    void nowPlayingItem;
    const audio = document.querySelector<HTMLAudioElement>('audio#apple-music-player');

    return audio;
  }, [nowPlayingItem]);

  return playerElement;
};
