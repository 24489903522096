export const saveVolume = (): void => {
  const music = MusicKit.getInstance();
  if (music.volume) {
    localStorage.setItem('app-volume', `${music.volume}`);
  }
};

export const getVolume = (): number => {
  const v = localStorage.getItem('app-volume') ?? '';
  const volume = parseFloat(v) ?? 0.5;
  if (!!volume && typeof volume === 'number') {
    return volume;
  }
  return 0.5;
};
